<template>
    <div
        v-if="dialog"
        class="confirm-modal"
        @click.self="close()"
    >
        <div class="confirm-modal__dialog">
            <header class="confirm-modal__header">
                <h2>Add your phone number</h2>
                <span
                    class="confirm-modal__close"
                    @click="close()"
                />
            </header>
            <section class="confirm-modal__content">
                <span>Please fill in your phone number</span>
                <div
                    class="phone-container"
                >
                    <phone-number />
                </div>
            </section>
            <footer>
                <div class="confirm-modal__two-button-block">
                    <button
                        class="btn-base btn-border button"
                        @click.prevent="close()"
                    >
                        skip
                    </button>
                    <button
                        class="btn-base btn-main button"
                        :class="{'btn-disabled': !isFormValid}"
                        @click.prevent="confirm()"
                    >
                        save
                    </button>
                </div>
            </footer>
        </div>
    </div>
</template>

<script>
import { eventBus } from '@/helpers/event-bus';
import { mapGetters, createNamespacedHelpers } from 'vuex'
import PhoneNumber from '@/components/common/form-elements/phone/PhoneNumber';
import {
    ACTION_CLIENT_UPDATE_PHONE
} from '@/store/modules/client/action-types';

const {
    mapActions: mapClientActions
} = createNamespacedHelpers('client');

export default {
    name: 'PhoneAddModal',
    components: {
        PhoneNumber
    },
    data: () => ({
        dialog: false
    }),
    computed: {
        ...mapGetters('phone', [
            'isPhoneVerified',
            'getPhoneObject'
        ]),
        isFormValid() {
            return this.isPhoneVerified
        }
    },
    methods: {
        ...mapClientActions({
            updatePhone: ACTION_CLIENT_UPDATE_PHONE
        }),
        open() {
            this.dialog = true
        },
        close() {
            this.dialog = false
        },
        async confirm() {
            try {
                await this.updatePhone({
                    ...this.getPhoneObject
                })
                eventBus.$emit('showSnackBar', 'Your phone has been changed', 'success');
                this.close()
            } catch (err) {
                eventBus.$emit('showSnackBar', err, 'error');
                console.error(err)
            }
        }
    }
}
</script>

<style lang="scss">
.confirm-modal {
    .form-input__fieldset {
        margin: 0;
    }
    .phone-container {
        margin: 20px 0;
    }
}
</style>
