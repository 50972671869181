const ClientAccount = () => import('@/views/account/Dashboard.vue')

export default [
    {
        path: '/',
        component: ClientAccount,
        name: 'account',
        meta: {
            layout: 'EmptyLayout',
            showHeader: true,
            requiresAuth: true
        }
    }
]
