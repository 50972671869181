const Credits = () => import('@/views/account/CreditsPage.vue')

export default [
    {
        path: '/credits',
        component: Credits,
        name: 'credits',
        meta: {
            requiresAuth: true,
            layout: 'BreadcrumbsLayout',
            breadcrumbs: {
                name: 'Credits',
                additionalClass: 'bc-horizontal bc-credits',
                excerpt: 'Things like friend\'s commissions or store credits will appear in this ledger. This does not show your actual purchases; only the bonuses that we credit to your internal account.'
            }
        }
    }
]
