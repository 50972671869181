<template>
    <div
        v-if="!isLoading"
        id="app"
    >
        <component
            :is="layout"
            v-if="layout && isLoaded"
        />
        <global-modals />
        <Notifier v-if="$socket.isConnected" />
        <SupportMessenger v-if="layout !== 'MessengerLayout'" />
        <update-notifications />
    </div>
</template>

<script>
import GlobalModals from '@/components/GlobalModals.vue'
import BreadcrumbsLayout from '@/layouts/BreadcrumbsLayout.vue'
import EmptyLayout from '@/layouts/EmptyLayout.vue'
import SupportMessenger from '@/components/support/supportMessenger/SupportMessenger'
import UpdateNotifications from '@/components/update-notifications/Main.vue'

// mixins
import authMixin from '@/mixins/global/authMixin.js'
import pwaMixin from '@/mixins/global/pwaMixin.js'
import loggedInUserMixin from '@/mixins/global/loggedInUserMixin.js'

import { eventBus } from '@/helpers/event-bus/'
import { getSettings } from '@/services/settings/'
import MessengerLayout from '@/layouts/MessengerLayout.vue'
import Notifier from '@/components/Notifier.vue'

export default {
    components: {
        BreadcrumbsLayout,
        EmptyLayout,
        MessengerLayout,
        GlobalModals,
        SupportMessenger,
        Notifier,
        UpdateNotifications
    },
    mixins: [
        authMixin,
        pwaMixin,
        loggedInUserMixin
    ],
    metaInfo: {
        title: 'Home',
        titleTemplate: 'Dashboard | %s'
    },
    data() {
        return {
            isLoading: true
        }
    },
    computed: {
        layout() {
            return this.$route.meta.layout
        }
    },
    async created() {
        // offline listener
        window.addEventListener('offline', () => {
            const errorString = 'There is a problem with the internet connection, try refreshing the page please.'
            eventBus.$emit('showSnackBar', errorString, 'error')
        })
        try {
            this.isLoading = true
            await getSettings()
        } catch (error) {
            eventBus.$emit('showSnackBar', error, 'error')
        } finally {
            this.isLoading = false
        }
    },
    head: {
        meta: [
            { property: 'og:image', content: `/img/icons/${process.env.VUE_APP_DOMAIN}/og_image.png` }
        ]
    }
}
</script>
