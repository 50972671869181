<template>
    <header class="header-empty-layout">
        <div class="header-empty-layout__logo">
            <a
                :href="'https://' + getAppDomain"
                class="header-empty-layout__logo-img"
            />
        </div>
        <div class="header__info">
            <p>
                <span class="header-empty-layout__link-text">{{ checkLoginOrRegisterLink.linkText }}</span>
                <router-link
                    class="header-empty-layout__btn btn-border btn-base"
                    :to="{
                        name: checkLoginOrRegisterLink.route,
                        params: {}
                    }"
                >
                    {{ checkLoginOrRegisterLink.linkTitle }}
                </router-link>
            </p>
        </div>
    </header>
</template>

<script>

export default {
    name: 'EnteringFormHeader',
    computed: {
        getAppDomain() {
            return process.env.VUE_APP_DOMAIN;
        },
        checkLoginOrRegisterLink() {
            switch (this.$route.name) {
            case 'register':
                return {
                    route: 'login',
                    linkTitle: 'Log in',
                    linkText: 'If you are an existing user'
                }
            case 'forgot-password':
                return {
                    route: 'login',
                    linkTitle: 'Back to login',
                    linkText: ''
                }
            default:
            }
            return {
                route: 'register',
                linkTitle: 'Register',
                linkText: 'If you are not a client yet'
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.header-empty-layout {
    position: sticky;
    width: 100%;
    top: 0;
    z-index: 51;
    height: 60px;
    background: rgba(255, 255, 255, 0.7);
    box-shadow: 0px 20px 24px -4px rgb(16 24 40 / 8%), 0px 8px 8px -4px rgb(16 24 40 / 3%);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 30px;
    &__logo{
        display: flex;
        align-items: center;
        position: relative;
    }
    &__logo-img{
        position: relative;
        width: $logo-img-width;
        height:  $logo-img-m1200-height;
        background: $logo-img;
        display: flex;
        align-items: center;
        @media all and (max-width: 480px) {
            width: $logo-img-m480-width;
            height: $logo-img-m480-height;
            background: $logo-img-mobile no-repeat;
        }
    }
    &__btn{
        border: 1px solid $main-color;
        height: 36px;
        &:hover {
            background-color: $main-color;
            color: #fff;
        }
    }
    &__link-text{
        color: $font-color-main;
        font-weight: 500;
        margin-right: 15px;
        @media all and (max-width: 768px) {
            display: none;
        }
    }
}
</style>
