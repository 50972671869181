<template>
    <footer
        id="page-footer"
        class="page-footer"
    >
        <div class="page-footer__inside">
            <div class="page-footer__row page-footer__row-top">
                <div style="display: flex; flex-wrap: wrap; width: 100%;">
                    <div class="page-footer__row-top__left">
                        <div
                            v-for="(colom, index) in coloms"
                            :key="index"
                            class="page-footer__row-top__left-column"
                        >
                            <h2 class="page-footer__row-top__left-column__title">
                                {{ colom.name }}
                            </h2>
                            <ul class="page-footer__row-top__left-column__list">
                                <li
                                    v-for="(children, childrenIndex) in colom.childrens"
                                    :key="childrenIndex"
                                    class="page-footer__row-top__left-column__list-item"
                                >
                                    <a
                                        v-if="children.link"
                                        :href="children.link"
                                        target="_blank"
                                        class="page-footer__row-top__left-column__list-item__link"
                                    >{{ children.name }}</a>
                                    <router-link
                                        v-else
                                        :to="children.to"
                                        class="page-footer__row-top__left-column__list-item__link"
                                    >
                                        {{ children.name }}
                                    </router-link>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="page-footer__row-top__right">
                        <h3 class="page-footer__row-top__right-title">
                            {{ $t('components.footer.open.title') }}
                        </h3>
                        <span
                            class="page-footer__row-top__right-desc"
                        >{{ $t('components.footer.open.subtitle') }}</span>
                        <router-link
                            :to="{name: 'support_form'}"
                            class="btn-base btn-main page-footer__row-top__right-ask"
                        >
                            {{ $t('components.footer.open.button') }}
                        </router-link>
                    </div>
                </div>
                <div class="page-footer__cosial-pay">
                    <div class="page-footer__cosial-pay-wrapper">
                        <h3 class="page-footer__row-top__right-title">
                            {{ $t('components.footer.payments') }}
                        </h3>
                        <div class="page-footer__row-top__right-pay">
                            <span
                                v-for="item in cards"
                                :key="item.card"
                                class="page-footer__row-top__right-pay__item"
                            >
                                <img
                                    :src="require(`@/assets/img/svg/${item.card}.svg`)"
                                    :alt="item.card"
                                >
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </footer>
</template>
<script>
export default {
    data() {
        return {
            api: process.env.VUE_APP_DOMAIN,
            apiContent: process.env.VUE_APP_API_CONTENT
        }
    },
    computed: {
        locales() {
            return this.$store.state.locales.locales
        },
        showLocales() {
            return !!process.env.VUE_APP_I18N_LOCALE
        },
        coloms() {
            return [
                {
                    name: this.$t('components.footer.links.resources'),
                    childrens: [
                        // writers
                        {
                            name: this.$t('components.footer.links.writers'),
                            to: { name: 'writers' }
                        },
                        {
                            name: this.$t('components.footer.links.terms'),
                            to: { name: 'terms' }
                        },
                        {
                            name: this.$t('components.footer.links.privacy'),
                            to: { name: 'privacy' }
                        }
                    ]
                },
                {
                    name: this.$t('components.footer.links.shortcuts'),
                    childrens: [
                        // writers
                        // {
                        //     name: this.$t('components.footer.links.login'),
                        //     to: { name: 'login' }
                        // },
                        // order form
                        // {
                        //     name: this.$t('components.footer.links.register'),
                        //     to: { name: 'register' }
                        // },
                        // order form
                        {
                            name: this.$t('components.footer.links.order'),
                            to: { name: 'order' }
                        },
                        {
                            name: this.$t('components.footer.links.support'),
                            to: { name: 'support_form' }
                        }
                    ]
                }
            ]
        },
        cards() {
            return [
                {
                    card: 'visa'
                },
                {
                    card: 'mastercard'
                },
                {
                    card: 'Amex'
                },
                {
                    card: 'DinersClub'
                },
                {
                    card: 'Jcb'
                },
                {
                    card: 'Maestro'
                }
            ]
        }
    },
    created() {
        this.$i18n.locale = this.$store.state.locales.locale
    },
    methods: {
        setLocale(locale) {
            this.$store.dispatch('locales/SET_LANG_ACTION', locale)
            this.$i18n.locale = locale
        }
    }
}
</script>

<style scoped lang="scss">
    .page-footer__langs {
        color: $footer-color-text;
        display: flex;
        @media (max-width:768px) {
            flex-direction: row;
        }
        &__item {
            margin-right: 20px;
            display: flex;
            align-items: center;
            text-transform: capitalize;
            cursor: pointer;
            img {
                width: 50px;
                margin-right: 10px;
            }
        }
    }

    .page-footer__cosial-pay{
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }

    .page-footer__cosial-pay-wrapper{
        padding-top: 20px;
        &:last-child{
            padding-right: 40px;
        }
    }

    .page-footer_social-link {
        margin-left: 20px;
        &:first-child   {
            margin-left: 0;
        }
    }
</style>
