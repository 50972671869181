import Vue from 'vue'
import Router from 'vue-router'

import { scroller } from 'vue-scrollto/src/scrollTo'
import { routes } from '@/router/routesAll.js'

import Auth from '@/helpers/auth/'

Vue.use(Router)

function shouldRedirectToAccount(toRoute) {
    const blockedRoutes = ['login', 'register', 'forgot-password']
    const index = blockedRoutes.findIndex((route) => route === toRoute)
    return index !== -1 && Auth.userAuth()
}

const router = new Router({
    routes,
    mode: 'history',
    scrollBehavior(to, from, savedPosition) {
        if (to.name === from.name) return false
        // if need scroll to id
        if (to.hash) {
            setTimeout(() => {
                const element = document.getElementById(to.hash.replace(/#/, ''))
                if (element && element.scrollIntoView) {
                    const ScrollTo = scroller();
                    ScrollTo(element, {
                        duration: 600,
                        offset: -60
                    });
                    element.scrollIntoView({ block: 'start', behavior: 'smooth' })
                }
            }, 0)
        }
        if (from.name === 'orders' && to.name.includes('order_')) {
            return { x: 0, y: 0 }
        }
        if (savedPosition || to.meta.scrollToTop === false) {
            return savedPosition
        }
        return { x: 0, y: 0 }
    }
});

router.beforeEach(async (to, from, next) => {
    // redirect logged in user to account page, in case an irrelevant route is accessed
    if (shouldRedirectToAccount(to.name)) {
        next({ name: 'account' })
        return
    }

    // all routes processing logic
    if (to.matched.some((record) => record.meta.requiresAuth)) {
        if (Auth.isLogged()) {
            next()
        } else {
            Auth.logout()
            Auth.logoutVuex()
            localStorage.setItem('login_last_location', window.location.href)
            next({ name: 'login' })
        }
    } else {
        next();
    }
})

export default router
