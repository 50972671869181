<template>
    <div class="support-messenger_form">
        <!-- Email -->
        <div
            v-if="!clientIsAuth"
            class="support-messenger_form__row"
        >
            <div class="support-messenger_form__col">
                <form-input
                    v-model.trim="email"
                    legend="E-mail"
                    type="text"
                    name="email"
                    prepend-icon="EmailOutlineIcon"
                    placeholder="Email"
                    :field.sync="email"
                    :errors="validationMessage($v.email)"
                    @input.native="$v.email.$touch()"
                    @blur.native="$v.email.$touch()"
                />
            </div>
        </div>
        <!-- linked order -->
        <div
            v-if="clientIsAuth"
            class="support-messenger_form__row support-messenger_form__row--order"
        >
            <reference-order
                label="I have a problem with a specific order"
                :errors="validationMessage($v.linked_to_order)"
            />
        </div>
        <!-- Radio type -->
        <div class="support-messenger_form__row support-messenger_form__row--radio">
            <div class="support-messenger_form__col">
                <h2>What type of request do you have?</h2>
                <form-radio-group
                    :classes="'support-form_group'"
                    :errors="validationMessage($v.type)"
                    @change.native="$v.type.$touch()"
                    @blur.native="$v.type.$touch()"
                >
                    <div
                        v-for="(item, index) in getTypes"
                        :key="index"
                        class="support-form_group-item"
                    >
                        <form-radio
                            v-model="type"
                            :text="item.text"
                            :data="item.value"
                            :is-checked="type === item.value"
                            name="type"
                            :field.sync="type"
                        />
                    </div>
                </form-radio-group>
            </div>
        </div>

        <!-- Radio problem -->
        <div
            v-if="type === `complaint`"
            class="support-messenger_form__row support-messenger_form__row--radio"
        >
            <div class="support-messenger_form__col">
                <h2>What is the problem?</h2>
                <form-radio-group
                    :classes="'support-form_group'"
                    :errors="validationMessage($v.problem)"
                    @change.native="$v.problem.$touch()"
                    @blur.native="$v.problem.$touch()"
                >
                    <div
                        v-for="(item, index) in getProblems"
                        :key="index"
                        class="support-form_group-item"
                    >
                        <form-radio
                            v-model="problem"
                            :text="item.text"
                            :data="item.value"
                            :is-checked="problem === item.value"
                            name="problem"
                            :field.sync="problem"
                        />
                    </div>
                </form-radio-group>
            </div>
        </div>

        <!-- Radio resolved -->
        <div
            v-if="type === `complaint`"
            class="support-messenger_form__row support-messenger_form__row--radio"
        >
            <div class="support-messenger_form__col">
                <h2>How would you like it resolved?</h2>
                <form-radio-group
                    :classes="'support-form_group'"
                    :errors="validationMessage($v.resolution)"
                    @change.native="$v.resolution.$touch()"
                    @blur.native="$v.resolution.$touch()"
                >
                    <div
                        v-for="(item, index) in getResolution"
                        :key="index"
                        class="support-form_group-item"
                    >
                        <form-radio
                            v-model="resolution"
                            :text="item.text"
                            :data="item.value"
                            :is-checked="resolution === item.value"
                            name="resolution"
                            :field.sync="resolution"
                        />
                    </div>
                </form-radio-group>
            </div>
        </div>

        <!-- Subject -->
        <div class="support-messenger_form__row">
            <div class="support-messenger_form__col">
                <form-input
                    v-model="$v.subject.$model"
                    legend="Subject"
                    type="text"
                    name="subject"
                    :field.sync="subject"
                    :errors="validationMessage($v.subject)"
                    @input.native="$v.subject.$touch()"
                    @blur.native="$v.subject.$touch()"
                />
            </div>
        </div>

        <!-- Message -->
        <div class="support-messenger_form__row">
            <div class="support-messenger_form__col">
                <form-textarea
                    v-model="$v.message.$model"
                    legend="Message"
                    type="text"
                    name="problem"
                    :field.sync="message"
                    :errors="validationMessage($v.message)"
                    @input.native="$v.message.$touch()"
                    @blur.native="$v.message.$touch()"
                />
            </div>
        </div>

        <!-- Submit -->
        <div class="support-messenger_form__row">
            <div class="support-messenger_form__col">
                <custom-button
                    v-if="clientIsAuth"
                    :class="$v.$invalid ? 'btn-base btn-disabled' : 'btn-base btn-main'"
                    :loading="ticketCreating"
                    @on-btn-click="submit"
                >
                    <button-loader v-if="ticketCreating" />
                    Proceed
                </custom-button>
                <RecaptchaButton
                    v-else
                    class="btn-main"
                    :validate-params="$v.$invalid"
                    @checkField="submit"
                    @captchaVerify="validateForm"
                >
                    <template slot="button-content">
                        <button-loader v-if="ticketCreating" />
                        Proceed
                    </template>
                </RecaptchaButton>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { scroller } from 'vue-scrollto/src/scrollTo'
// Validation
import { validationMixin } from 'vuelidate'
import { validationMessage } from 'vuelidate-messages'
import formValidationMixin from '@/mixins/formValidationMixin'
import { validationRules } from '@/validation/support/Rules'
import { formMessages } from '@/validation/support/Messages'
// types
import { types } from '@/components/support/supportTypes.js'

// components
import ReferenceOrder from '@/components/support/ReferenceOrder';
import RecaptchaButton from '@/components/common/RecaptchaButton';
import ButtonLoader from '@/components/common/ButtonLoader';

export default {
    components: {
        ReferenceOrder,
        RecaptchaButton,
        ButtonLoader
    },
    mixins: [
        validationMixin,
        formValidationMixin
    ],
    props: {
        ticketCreating: {
            type: Boolean,
            reqired: true
        }
    },
    validations: {
        ...validationRules
    },
    data() {
        return {
            email: '',
            type: '',
            problem: '',
            resolution: '',
            subject: '',
            message: '',
            verified: true
        }
    },
    computed: {
        ...mapGetters('client', {
            clientIsAuth: 'getterIsAuth'
        }),
        getTypes() {
            return types.type.filter((item) => {
                if (!this.clientIsAuth && item.value === 'complaint') {
                    return false
                }
                return true
            })
        },
        getProblems() {
            return types.problem
        },
        getResolution() {
            return types.resolution
        }
    },
    methods: {
        validationMessage: validationMessage(formMessages),
        async validateForm(payload) {
            await this.$v.$touch()
            if (!this.$v.$invalid) {
                this.submit(payload)
            } else {
                const firstErrorElement = document.querySelector('.error-font-color');
                const firstScrollTo = scroller()
                firstScrollTo(firstErrorElement, 200, { offset: -90 })
                this.validate()
            }
        },
        submit(recaptcha) {
            this.$emit('createTicket', {
                email: this.email,
                type: this.type,
                problem: this.problem,
                resolution: this.resolution,
                subject: this.subject,
                message: this.message,
                recaptcha
            })
        }
    }
}
</script>
