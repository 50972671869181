const AccSettingMain = () => import('@/views/account/Setting/Main')
const AccSettingPayMethod = () => import('@/views/account/Setting/PayMethod')
const AccSettingEmail = () => import('@/views/account/Setting/Email')
const AccSettingPassword = () => import('@/views/account/Setting/Password')
const AccSettingPhone = () => import('@/views/account/Setting/Phone')
const AccSettingAddress = () => import('@/views/account/Setting/Address')
const AccSettingWriters = () => import('@/views/account/Setting/Writers')
const AccSettingNotifications = () => import('@/views/account/Setting/Notifications')

export default [
    {
        path: '/settings',
        component: AccSettingMain,
        name: 'profile',
        meta: {
            requiresAuth: true,
            layout: 'BreadcrumbsLayout',
            breadcrumbs: {
                name: 'Settings',
                additionalClass: 'bc-wrapper bc-horizontal bc-horizontal--small'
            }
        }
    },
    {
        path: '/settings/payment-method',
        component: AccSettingPayMethod,
        name: 'profile_payment-method',
        meta: {
            requiresAuth: true,
            layout: 'BreadcrumbsLayout',
            breadcrumbs: {
                name: 'Withdrawal Method',
                additionalClass: 'bc-wrapper bc-horizontal bc-horizontal--small',
                parent_route_names: ['profile']
            }
        }
    },
    {
        path: '/settings/email',
        component: AccSettingEmail,
        name: 'profile_email',
        meta: {
            requiresAuth: true,
            layout: 'BreadcrumbsLayout',
            breadcrumbs: {
                name: 'Change Email',
                additionalClass: 'bc-wrapper bc-horizontal bc-horizontal--small',
                parent_route_names: ['profile']
            }
        }
    },
    {
        path: '/settings/password',
        component: AccSettingPassword,
        name: 'profile_password',
        meta: {
            requiresAuth: true,
            layout: 'BreadcrumbsLayout',
            breadcrumbs: {
                name: 'Change Password',
                additionalClass: 'bc-wrapper bc-horizontal bc-horizontal--small',
                parent_route_names: ['profile']
            }
        }
    },
    {
        path: '/settings/phone',
        component: AccSettingPhone,
        name: 'profile_phone',
        meta: {
            requiresAuth: true,
            layout: 'BreadcrumbsLayout',
            breadcrumbs: {
                name: 'Change Address',
                additionalClass: 'bc-wrapper bc-horizontal bc-horizontal--small',
                parent_route_names: ['profile']
            }
        }
    },
    {
        path: '/settings/address',
        component: AccSettingAddress,
        name: 'profile_address',
        meta: {
            requiresAuth: true,
            layout: 'BreadcrumbsLayout',
            breadcrumbs: {
                name: 'Change Address',
                additionalClass: 'bc-wrapper bc-horizontal bc-horizontal--small',
                parent_route_names: ['profile']
            }
        }
    },
    {
        path: '/settings/writers',
        component: AccSettingWriters,
        name: 'profile_writers',
        meta: {
            requiresAuth: true,
            layout: 'BreadcrumbsLayout',
            breadcrumbs: {
                name: 'Writer Preferences',
                additionalClass: 'bc-wrapper bc-horizontal bc-horizontal--small',
                parent_route_names: ['profile']
            }
        }
    },
    {
        path: '/settings/notifications',
        component: AccSettingNotifications,
        name: 'profile_notifications',
        meta: {
            requiresAuth: true,
            layout: 'BreadcrumbsLayout',
            breadcrumbs: {
                name: 'Notification settings',
                additionalClass: 'bc-wrapper bc-horizontal bc-horizontal--small',
                parent_route_names: ['profile']
            }
        }
    }
]
