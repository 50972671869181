export const ACTION_LOGIN_CLIENT = 'ACTION_LOGIN_CLIENT'

export const ACTION_LOGOUT_CLIENT = 'ACTION_LOGOUT_CLIENT'

export const ACTION_CREATE_CLIENT = 'ACTION_CREATE_CLIENT'

export const ACTION_UPDATE_CLIENT_DATA = 'ACTION_UPDATE_CLIENT_DATA'

export const ACTION_CLIENT_UPDATE_SETTINGS = 'ACTION_CLIENT_UPDATE_SETTINGS'

export const ACTION_PAY_WITH_PAYPAL_CREATE = 'ACTION_PAY_WITH_PAYPAL_CREATE'

export const ACTION_PAY_WITH_PAYPAL_EXECUTE = 'ACTION_PAY_WITH_PAYPAL_EXECUTE'

export const ACTION_PAY_WITH_CARD_SECOND = 'ACTION_PAY_WITH_CARD_SECOND'

export const ACTION_REFRESH_TOKEN = 'ACTION_REFRESH_TOKEN'

export const ACTION_CLIENT_FRIEND_ADD = 'ACTION_CLIENT_FRIEND_ADD'

export const ACTION_CLIENT_UPDATE_EMAIL = 'ACTION_CLIENT_UPDATE_EMAIL'

export const ACTION_CLIENT_UPDATE_PAYMETHOD = 'ACTION_CLIENT_UPDATE_PAYMETHOD'

export const ACTION_CLIENT_UPDATE_PASSWORD = 'ACTION_CLIENT_UPDATE_PASSWORD'

export const ACTION_CLIENT_UPDATE_PHONE = 'ACTION_CLIENT_UPDATE_PHONE'

export const ACTION_CLIENT_UPDATE_ADDRESS = 'ACTION_CLIENT_UPDATE_ADDRESS'

export const ACTION_CLIENT_UPDATE_WRITERS = 'ACTION_CLIENT_UPDATE_WRITERS'

export const ACTION_CLIENT_RESET_PASSWORD_SEND_EMAIL = 'ACTION_CLIENT_RESET_PASSWORD_SEND_EMAIL'

export const ACTION_CLIENT_RESET_PASSWORD_SET_NEW = 'ACTION_CLIENT_RESET_PASSWORD_SET_NEW'

export const ACTION_CLIENT_SOCIAL_LOGIN_GET_URL = 'ACTION_CLIENT_SOCIAL_LOGIN_GET_URL'

export const ACTION_CLIENT_SOCIAL_LOGIN_VERIFY = 'ACTION_CLIENT_SOCIAL_LOGIN_VERIFY'

export const LOGIN_RESET_MODULES = 'LOGIN_RESET_MODULES'

export const ACTION_CLIENT_TOUCH = 'ACTION_CLIENT_TOUCH'

export const ACTION_REGISTER_OAUTH = 'ACTION_REGISTER_OAUTH'

export const ACTION_AUTHORIZE_IN_FRONTEND = 'ACTION_AUTHORIZE_IN_FRONTEND'
