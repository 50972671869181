<template>
    <div class="phone__row">
        <phone-number-select
            v-model="$v.country.$model"
            label="Code"
            tabindex="0"
            :errors="validationMessage($v.country)"
            :is-valid="!$v.country.$anyError && !$v.country.$invalid"
            @change="$v.country.$touch()"
            @blur="$v.country.$touch()"
        />
        <phone-number-input
            v-model="phoneNumberValue"
            :field.sync="phoneNumberValue"
            :errors="validationMessage($v.phone_number)"
            :is-valid="$v.phone_number.$dirty && !$v.phone_number.$anyError"
            @input.native="$v.phone_number.$touch()"
            @blur.native="$v.phone_number.$touch()"
            @phoneValidated="$emit('phoneValidated', $event)"
        />
        <div class="phone-verification__btn">
            <RecaptchaButton
                :validate-params="$v.$invalid"
                @checkField="verificationStart"
                @captchaVerify="verificationStart"
            >
                <template slot="button-content">
                    <button-loader v-if="phone_validation_loading" />
                    <span v-else>Verify</span>
                </template>
            </RecaptchaButton>
        </div>
    </div>
</template>

<script>

import { validationMixin } from 'vuelidate';
import { validationMessage } from 'vuelidate-messages'
import { mapState } from 'vuex';
import Messages from '@/helpers/InterfaceMessages_en'
import { eventBus } from '@/helpers/event-bus/'

import ButtonLoader from '@/components/common/ButtonLoader.vue';

import Api from '@/helpers/api/index.js'

// validation
import { validationRules } from '@/validation/phone/Rules.js'
import { formMessages } from '@/validation/phone/Messages.js'
import formValidationMixin from '@/mixins/formValidationMixin.js'

// VUEX
import { mapPhoneFields } from '@/store/modules/phone'
import RecaptchaButton from '@/components/common/RecaptchaButton';
import PhoneNumberSelect from './PhoneNumberSelect';
import PhoneNumberInput from './PhoneNumberInput';

export default {
    name: 'PhoneNumberField',
    components: {
        PhoneNumberInput,
        PhoneNumberSelect,
        ButtonLoader,
        RecaptchaButton
    },
    mixins: [
        validationMixin,
        formValidationMixin
    ],
    validations: {
        ...validationRules
    },
    data() {
        return {
            phoneNumberValue: '',
            phoneValidationStatus: false,
            phone_validation_loading: false
        }
    },
    computed: {
        ...mapState('phone', {
            started: 'started',
            verified: 'verified'
        }),
        ...mapPhoneFields([
            'form_data.country',
            'form_data.phone_number'
        ]),
        isFormValid() {
            return !this.$v.$invalid
        }
    },
    watch: {
        phoneNumberValue(newPhoneNumber) {
            this.phone_number = newPhoneNumber
        }
    },
    mounted() {
        if (this.$route.name === 'profile_phone') {
            this.country = {}
            this.phone_number = ''
            eventBus.$on('saveSettings', () => {
                if (this.$v.$invalid) {
                    this.validate()
                    return
                }
                eventBus.$emit('validate', true)
            })
            return
        }
        eventBus.$on('clearPhone', () => {
            this.$v.$reset()
        })
        this.phoneNumberValue = this.phone_number
    },
    methods: {
        validationMessage: validationMessage(formMessages),
        async verificationStart(payload) {
            if (this.$v.$invalid) {
                await this.isValid()
                const errorName = this.findNameOfFirstError(this.$v);
                eventBus.$emit('showSnackBar', `Please complete the form: ${errorName}`, 'error');
            } else {
                this.phone_validation_loading = true
                Api.post('/api/phone-verification/send-pin', {
                    phone: this.phone_number,
                    phone_code: this.country.value,
                    recaptcha: payload
                }).then(() => {
                    this.$store.commit('phone/START')
                    this.phone_validation_error = false
                    eventBus.$emit('showSnackBar', Messages.modal_sent_phone_verification_pin.body, 'success');
                }).catch((error) => {
                    eventBus.$emit('showSnackBar', error, 'error');
                    this.phone_validation_error = true
                    this.phone_validation_message = error
                    setTimeout(() => {
                        this.phone_validation_error = false
                        this.phone_validation_message = ''
                    }, 2000)
                }).finally(() => {
                    this.phone_validation_loading = false
                })
            }
        }
    }
}
</script>
