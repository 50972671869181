const NotFound = () => import('@/views/NotFound/Main.vue')

export default [
    {
        name: '404',
        path: '/404',
        component: NotFound,
        meta: {
            requiresAuth: false,
            layout: 'BreadcrumbsLayout',
            breadcrumbs: {
                bgWrapper: true,
                name: 'Page Not Found'
            }
        }
    },
    {
        path: '/:pathMatch(.*)*',
        redirect: {
            name: '404'
        }
    }
]
