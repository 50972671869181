<template lang="html">
    <div class="support__phones">
        <div
            v-click-outside.prevent="() => openSupportPhones = false"
            class="support__phones-header"
            @click.prevent="openSupportPhones = !openSupportPhones"
        >
            <p
                class="support__phones-info"
                :class="{'active' : openSupportPhones}"
            >
                <span>{{ $t('components.support.info') }}</span>
            </p>
        </div>

        <transition name="slide-to-bottom">
            <div
                v-if="openSupportPhones"
                class="support-dropdown"
            >
                <template v-if="false && phonesSupport">
                    <!-- убрать false после решения проблемы с телефонами -->
                    <div class="support-dropdown__top">
                        <h4 class="support-dropdown__title">
                            {{ $t('components.support.dropdown__title') }}
                        </h4>
                        <ul
                            class="support-dropdown__phone-list"
                        >
                            <li
                                v-for="(item, index) in phonesSupport"
                                :key="index"
                            >
                                <a
                                    :class="'support-dropdown__icon-' + item.icon"
                                    :href="'tel:' + item.phoneNumber.replace(/-/g, '')"
                                >
                                    {{ item.phoneNumber }}
                                </a>
                                <div class="support-dropdown__location">
                                    <span class="support-dropdown__country">{{ item.country }}</span>
                                    <span class="support-dropdown__flag" />
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div class="support-dropdown__tip">
                        {{ $t('components.support.dropdown__tip') }}
                    </div>
                </template>
                <div class="support-dropdown__bottom">
                    <p>
                        {{ $t('components.support.dropdown__bottom__header') }}
                        <span>
                            {{ $t('components.support.dropdown__bottom__text') }}
                        </span>
                    </p>

                    <p class="support-dropdown__bottom-bottom bottom-baner">
                        <strong>
                            {{ $t('components.support.dropdown__bottom__baner') }}
                        </strong>
                    </p>

                    <router-link
                        to="/support/ticket/new"
                        rel="nofollow"
                        href="/support/ticket/new"
                        class="btn-base btn-main"
                    >
                        {{ $t('components.support.dropdown__bottom__botton') }}
                    </router-link>
                </div>
            </div>
        </transition>
        <div
            v-if="openSupportPhones"
            class="blurred-background"
        />
    </div>
</template>

<script>
import { mapState } from 'vuex'
import ClickOutside from 'vue-click-outside'

const Env = require('@/helpers/environment/index.js');

const EnvSettings = new Env(process.env.VUE_APP_DOMAIN);

export default {
    directives: {
        ClickOutside
    },
    props: {
        displayingMode: {
            type: String
        }
    },
    data: () => ({
        phonesSupport: EnvSettings.getPhones,
        openSupportPhones: false
    }),
    computed: {
        ...mapState('modals', {
            modal_client_support_phones: 'modal_client_support_phones'
        })
    }
}
</script>
<style lang="scss">
.support__phones {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100%;
    position: relative;
    padding: 0;
    cursor: pointer;
    user-select: none;

    &-info {
        display: flex;
        align-items: center;

        &::after, &::before {
            content: '';
            display: block;
        }

        &::before {
            min-width: 40px;
            height: 40px;
            mask-image: url('~@/assets/img/svg/phone-forwarded.svg');
            mask-repeat: no-repeat;
            mask-size: cover;
            mask-position: center;
            background: $main-color;
            margin-right: 10px;
        }

        &::after {
            min-width: 10px;
            width: 10px;
            height: 10px;
            mask-image: url('~@/assets/img/svg/next.svg');
            mask-position: center;
            mask-repeat: no-repeat;
            mask-size: contain;
            background: $font-color-main;
            margin-left: 10px;
            transform: rotate(90deg);
        }

        &.active {
            &::after {
                transform: rotate(270deg);
                background: $main-color;
            }

            span {
                color: $main-color;
            }
        }

        span {
            color: $font-color-main;
            font-weight: 500;
            font-size: 16px;
        }
    }

    .support-dropdown {
        height: auto;
        box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.07);
        transition: 0.2s;
        transform: rotateX(0deg);
    }
}
@media (max-width: 992px) {
    .support__phones-info {
        &::after, span {
            display: none;
        }

        &::before {
            margin-right: 0;
            width: 24px;
            height: 26px;
            mask-image: url('~@/assets/img/svg/phone.svg');
            mask-repeat: no-repeat;
            mask-size: cover;
            mask-position: center;
            background: $main-color;
        }
    }
}

@media (max-width: 576px) {
    .support__phones {
        display: none;
    }
}

</style>
