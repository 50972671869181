export const RESET = 'RESET'

export const LOGIN_CLIENT = 'LOGIN_CLIENT'

export const UPDATE_FORM_DATA = 'UPDATE_FORM_DATA'

export const UPDATE_CLIENT_DATA = 'UPDATE_CLIENT_DATA'

export const SET_PHONE_CODE_AND_COUNTRY = 'SET_PHONE_CODE_AND_COUNTRY'

export const UPDATE_CLIENT_EMAIL = 'UPDATE_CLIENT_EMAIL'

export const UPDATE_CLIENT_PHONE_DETAILS = 'UPDATE_CLIENT_PHONE_DETAILS'

export const LOGOUT_CLIENT = 'LOGOUT_CLIENT'

export const UPDATE_ADDRESS = 'UPDATE_ADDRESS'

export const UPDATE_BATCH_WITH_BATCHID = 'UPDATE_BATCH_WITH_BATCHID'

export const UPDATE_CHK = 'UPDATE_CHK'

export const SET_OAUTH_FORM_DATA = 'SET_OAUTH_FORM_DATA'

export const OPEN_SOCIAL_MODAL = 'OPEN_SOCIAL_MODAL'

export const CLOSE_SOCIAL_MODAL = 'CLOSE_SOCIAL_MODAL'

export const CLEAR_OAUTH_FORM_DATA = 'CLEAR_OAUTH_FORM_DATA'
