const Login = () => import(/* webpackChunkName: "login" */ '@/views/auth/Login.vue')
const Register = () => import(/* webpackChunkName: "register" */ '@/views/auth/Register.vue')
const ForgotPassword = () => import(/* webpackChunkName: "forgot-password" */ '@/views/auth/ForgotPassword.vue')
const Reset = () => import(/* webpackChunkName: "_reset" */'@/views/auth/ResetPassword')
const ResetSuccess = () => import(/* webpackChunkName: "_reset" */'@/views/auth/ResetPasswordSuccess')
const OAuth = () => import(/* webpackChunkName: "_reset" */'@/views/auth/OAuth')
const OAuthFinalize = () => import(/* webpackChunkName: "_reset" */'@/views/auth/FinalizeOAuth')
const AuthIntermediate = () => import(/* webpackChunkName: "_reset" */'@/views/auth/AuthIntermediate')

export default [
    {
        path: '/login',
        component: Login,
        name: 'login',
        meta: {
            layout: 'EmptyLayout'
        }
    },
    {
        path: '/forgot-password',
        component: ForgotPassword,
        name: 'forgot-password',
        props: {
            mode: 'forgot'
        },
        meta: {
            layout: 'EmptyLayout'
        }
    },
    {
        path: '/register',
        component: Register,
        name: 'register',
        meta: {
            layout: 'EmptyLayout'
        }
    },
    {
        path: '/finalize-auth',
        component: OAuthFinalize,
        name: 'finalize-auth',
        meta: {
            showHeader: true,
            layout: 'EmptyLayout'
        }
    },
    {
        path: '/auth/client/password/show-reset-form/:token',
        component: Reset,
        name: 'reset',
        meta: {
            layout: 'BreadcrumbsLayout',
            breadcrumbs: {
                name: 'Reset password',
                additionalClass: 'bc-horizontal bc-reset'
            }
        }
    },
    {
        path: '/reset-success/:token',
        component: ResetSuccess,
        name: 'reset-success',
        meta: {
            layout: 'EmptyLayout'
        }
    },
    {
        path: '/oauth/registration-complete/:service',
        component: OAuth,
        name: 'oauth',
        meta: {
            layout: 'EmptyLayout'
        }
    },
    {
        path: '/auth/loading',
        component: AuthIntermediate,
        name: 'auth-intermediate',
        meta: {
            layout: 'EmptyLayout'
        }
    }
]
