import Helpers from '@/helpers/functions/index.js'
import HttpInstance from '@/helpers/http/index.js'
import Auth from '../auth/index.js'
import { errorHandler } from './error-handler'

const axios = HttpInstance.create();

class Api {
    constructor() {
        this.networkErrorMessage = 'Backend unreachable'
    }

    post(url, payload, options = {}) {
        return new Promise((resolve, reject) => {
            // const data = JSON.parse(JSON.stringify(payload))
            axios.post(url, payload, options)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    this.processError(error, options)
                    reject(errorHandler(error.response.data))
                })
        });
    }

    put(url, payload, options = {}) {
        return new Promise((resolve, reject) => {
            // const data = JSON.parse(JSON.stringify(payload))
            axios.put(url, payload, options)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    this.processError(error, options)
                    reject(errorHandler(error.response.data))
                })
        });
    }

    delete(url, payload, options = { dialog: true }) {
        return new Promise((resolve, reject) => {
            // const data = JSON.parse(JSON.stringify(payload))
            axios.delete(url, { params: { ...payload } })
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    this.processError(error, options)
                    reject(errorHandler(error.response.data))
                })
        });
    }

    get(url, payload, options = { dialog: true }) {
        return new Promise((resolve, reject) => {
            axios.get(url, { params: payload })
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    this.processError(error, options)
                    reject(errorHandler(error.response.data))
                })
        })
    }

    async processError(error, options) {
        if (error.response.status === 401) {
            // token has expired. Frontend tries to send a request with an expired token and the backend rejects with a 401 code.
            // We update the front-end and clean up vuex and local storage.
            const message = 'Your session has expired, please log in.'
            Helpers.consoleLogWrapper(message, options)
            Auth.logout()
            Auth.logoutVuex()
            window.location.href = `${window.location.origin}/login`
        } else if (error.response) {
            // this will throw a snackbar message for all HTTP errors
            // to prevent the above behavior, we must pass { dialog: false } in options
            Helpers.consoleLogWrapper(error.response.data.message, options)
        } else {
            // network error
            // to prevent the above behavior, we must pass { dialog: false } in options
            Helpers.consoleLogWrapper(this.networkErrorMessage, options)
        }
    }
}

export default new Api()
