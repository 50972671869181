<template>
    <div
        v-if="!getterIsAuth"
        class="sign-block"
    >
        <router-link
            to="/login"
            class="sign-block-login"
        >
            <span class="sign-block-login-icon">.</span>
            <span class="sign-block-login-text">{{ $t('components.sign_block.text') }}</span>
        </router-link>
    </div>
    <div
        v-else
        class="sign-block"
        :class="{'sign-block__auth' : getterIsAuth}"
    >
        <alert-block />
        <control-panel-menu />
    </div>
</template>

<script>
// components
import { mapGetters } from 'vuex'
import ControlPanelMenu from '@/components/Header/ControlPanelMenu'
import AlertBlock from '@/components/Header/Alert/AlertBlock'
// vuex

export default {
    components: {
        ControlPanelMenu,
        AlertBlock
    },
    computed: {
        ...mapGetters('client', [
            'getterIsAuth'
        ])
    }
}
</script>

<style lang="scss" scoped>
.sign-block {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    margin-left: 30px;

    &-login {
        display: flex;
        color: $second-color;
        font-weight: 600;
        align-items: center;
        margin-right: 30px;
        &:hover {
            color: #ffa600;
            &-icon {
                background: #ffa600;
            }
        }

        &-icon {
            width: 24px;
            height: 24px;
            display: block;
            background-color: $main-color;
            transition: all 0.3s ease-in-out;
            mask-image: url("~@/assets/img/svg/user_account.svg");
            mask-size: contain;
            mask-position: center;
            mask-repeat: no-repeat;
            margin-right: 10px;

            img {
                filter: none;
            }
        }

        &-text {
            color: $font-color-main;
            font-weight: 500;
            font-size: 16px;
        }
    }
}

@media (max-width: 1200px) {
    .sign-block-login {
        &-text {
            display: none;
        }

        &-icon {
            width: 24px;
            height: 24px;
            margin-right: 0;
        }
    }
    .profile-dropdown {
        top: 60px;
        right: -80px;
        left: auto;
    }
}

@media (max-width: 576px) {
    .sign-block{
        margin-right: 25px;
    }
}
</style>
