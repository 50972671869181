import Api from '@/helpers/api/index.js'
import Auth from '@/helpers/auth/index.js'
import { getPromoHash } from '@/services/promoCompanies'
import { getReferralHash, deleteReferralHash } from '@/services/referral'
import { removeEmptyParamsFromRequest } from '@/helpers/utils/'
import {
    ACTION_LOGIN_CLIENT,
    ACTION_LOGOUT_CLIENT,
    ACTION_CREATE_CLIENT,
    ACTION_UPDATE_CLIENT_DATA,
    ACTION_CLIENT_UPDATE_EMAIL,
    ACTION_CLIENT_UPDATE_PAYMETHOD,
    ACTION_CLIENT_UPDATE_PASSWORD,
    ACTION_CLIENT_UPDATE_PHONE,
    ACTION_CLIENT_UPDATE_ADDRESS,
    ACTION_CLIENT_UPDATE_WRITERS,
    ACTION_CLIENT_UPDATE_SETTINGS,
    ACTION_CLIENT_RESET_PASSWORD_SEND_EMAIL,
    ACTION_CLIENT_RESET_PASSWORD_SET_NEW,
    ACTION_CLIENT_SOCIAL_LOGIN_GET_URL,
    ACTION_CLIENT_SOCIAL_LOGIN_VERIFY,
    ACTION_CLIENT_FRIEND_ADD,
    ACTION_REFRESH_TOKEN,
    LOGIN_RESET_MODULES,
    ACTION_CLIENT_TOUCH,
    ACTION_REGISTER_OAUTH,
    ACTION_AUTHORIZE_IN_FRONTEND
} from './action-types.js'

import {
    LOGIN_CLIENT,
    UPDATE_CLIENT_DATA,
    LOGOUT_CLIENT,
    UPDATE_ADDRESS,
    UPDATE_CHK,
    UPDATE_CLIENT_EMAIL,
    SET_OAUTH_FORM_DATA,
    CLEAR_OAUTH_FORM_DATA
} from './mutation-types.js'

export const actions = {
    // START Auth logic
    async [ACTION_REGISTER_OAUTH]({ dispatch }, payload) {
        const { data } = await Api.post('/api/auth/oauth/register',
            removeEmptyParamsFromRequest(
                {
                    ...payload,
                    hash_offer: getPromoHash() ?? null,
                    hash_invite: getReferralHash() ?? null
                }
            ))
        deleteReferralHash()
        await dispatch(ACTION_AUTHORIZE_IN_FRONTEND, data.token_data)
    },
    async [ACTION_CREATE_CLIENT]({ dispatch }, payload) {
        const { data } = await Api.post('/api/auth/register',
            removeEmptyParamsFromRequest(
                {
                    ...payload,
                    hash_offer: getPromoHash() ?? null,
                    hash_invite: getReferralHash() ?? null
                }
            ))
        deleteReferralHash()
        await dispatch(ACTION_AUTHORIZE_IN_FRONTEND, data.token_data)
    },
    async [ACTION_LOGIN_CLIENT]({ dispatch }, payload) {
        const { data } = await Api.post('/api/auth/login', payload)
        await dispatch(ACTION_AUTHORIZE_IN_FRONTEND, data.token_data)
    },
    async [ACTION_CLIENT_SOCIAL_LOGIN_VERIFY]({ commit, dispatch }, payload) {
        const { data } = await Api.get('/api/auth/oauth/callback', {
            provider: payload.provider,
            code: payload.code
        })
        if (data.action === 'register') {
            commit(SET_OAUTH_FORM_DATA, data)
            return { route_name: 'finalize-auth' }
        }
        if (data.token_data?.access_token) {
            await dispatch(ACTION_AUTHORIZE_IN_FRONTEND, data.token_data)
            return { route_name: 'auth-intermediate' }
        }
        return { route_name: 'login' }
    },
    async [ACTION_CLIENT_SOCIAL_LOGIN_GET_URL](ctx, payload) {
        const { data } = await Api.get('/api/auth/oauth/get-redirect-url', {
            provider: payload
        })
        return data
    },
    async [ACTION_REFRESH_TOKEN]({ commit }) {
        const { data } = await Api.post('/api/auth/token/refresh', { refresh_token: Auth.getRefreshToken() })
        Auth.login(data.token_data)
        commit(LOGIN_CLIENT)
    },
    async [ACTION_LOGOUT_CLIENT]({ commit }) {
        await Api.post('/api/auth/logout')
        Auth.logout()
        Auth.logoutVuex()
        commit(LOGOUT_CLIENT)
    },
    async [ACTION_CLIENT_TOUCH]() {
        await Api.get('/api/auth/touch')
    },
    async [ACTION_AUTHORIZE_IN_FRONTEND]({ commit }, token_data) {
        Auth.login(token_data)
        commit('phone/RESET', null, { root: true })
        commit(CLEAR_OAUTH_FORM_DATA)
        commit(LOGIN_CLIENT)
    },
    // END Auth logic
    [LOGIN_RESET_MODULES]({ commit }) {
        commit('phone/RESET', null, { root: true })
        commit('support/RESET', null, { root: true })
    },
    async [ACTION_UPDATE_CLIENT_DATA]({ commit }) {
        const { data } = await Api.get('/api/client/fetch-client-data')
        commit(UPDATE_CLIENT_DATA, data)
    },
    async [ACTION_CLIENT_FRIEND_ADD](ctx, payload) {
        await Api.post('/api/client/friend/invite-by-email', payload)
    },
    async [ACTION_CLIENT_UPDATE_EMAIL]({ commit }, payload) {
        await Api.put('/api/client/update-email', payload)
        commit(UPDATE_CLIENT_EMAIL, payload)
    },
    async [ACTION_CLIENT_UPDATE_PAYMETHOD](ctx, payload) {
        await Api.put('/api/client/update-paymethod', payload)
    },
    async [ACTION_CLIENT_UPDATE_ADDRESS]({ commit }, payload) {
        await Api.put('/api/client/contact-details', payload)
        commit(UPDATE_ADDRESS, payload)
    },
    async [ACTION_CLIENT_UPDATE_PHONE](ctx, payload) {
        await Api.put('/api/client/update-phone', payload)
    },
    async [ACTION_CLIENT_UPDATE_PASSWORD](ctx, payload) {
        await Api.put('/api/client/update-password', payload)
    },
    async [ACTION_CLIENT_UPDATE_WRITERS](ctx, payload) {
        await Api.put('/api/client/update-writers', payload)
    },
    async [ACTION_CLIENT_UPDATE_SETTINGS]({ commit }, payload) {
        await Api.put('/api/client/update-settings', payload)
        commit(UPDATE_CHK, payload)
    },
    async [ACTION_CLIENT_RESET_PASSWORD_SEND_EMAIL](ctx, payload) {
        await Api.post('/api/auth/forgot-password/send-email', payload)
    },
    async [ACTION_CLIENT_RESET_PASSWORD_SET_NEW](ctx, payload) {
        await Api.put('/api/auth/forgot-password/set-new-password', payload)
    }
}
