import { cloneDeep } from 'lodash'

import { updateField } from 'vuex-map-fields';
import { SchemaEmail } from '@/models/SchemaEmail.js'

import { initialState } from './state.js'

import {
    RESET,
    START,
    SUCCESS,
    FAILURE,
    SET_DEFAULT
} from './mutation-types'

export const mutations = {
    updateField,
    [RESET](state) {
        Object.assign(state, cloneDeep(initialState))
    },
    [START]: (state) => {
        state.started = true
    },
    [SUCCESS]: (state) => {
        state.verified = true
    },
    [FAILURE]: (state) => {
        state.verified = false
    },
    [SET_DEFAULT]: (state) => {
        state.verified = false
        state.started = false
        state.form_data = new SchemaEmail()
    }
}
