<template>
    <div
        v-click-outside="clickOutside"
        class="form-select"
        :class="{ 'error-border': validationError, 'success-border' : setValue, 'grey-border' : openListItem }"
    >
        <fieldset :class="openListItem ? 'form-select__fieldset active' : 'form-select__fieldset'">
            <legend
                class="form-select__legend"
                :class="{ 'error': validationError }"
            >
                {{ label }}
            </legend>
            <component
                :is="prependIcon"
                class="form-input__prepend-icon"
            />
            <input
                :class="inputClasses"
                :value="setValue"
                type="text"
                :name="name"
                autocomplete="off"
                :placeholder="placeholder || 'Select ' + name"
                @click.self="openListItem = !openListItem"
                @focus="$event.target.select()"
                @input="onInputChanged($event)"
            >
            <ul
                v-if="openListItem"
                class="form-select__list"
                :style="`${maxHeight && 'max-height:' + maxHeight + 'px' }`"
            >
                <li
                    v-for="(item, index) in filteredList"
                    :key="index"
                    class="form-select__list-item"
                    @click="selectItem(item.value)"
                >
                    {{ item.text }}
                </li>
            </ul>
            <div
                v-if="validationError"
                class="form-select__error"
            >
                {{ validationError }}
            </div>
        </fieldset>
    </div>
</template>

<script>
import BookOpenVariant from 'mdi-vue/BookOpenVariant.vue';
import FileDocument from 'mdi-vue/FileDocument.vue';
import ClickOutside from 'vue-click-outside'

export default {
    name: 'FormSelect',
    components: {
        BookOpenVariantIcon: BookOpenVariant,
        FileDocumentIcon: FileDocument
    },
    directives: {
        ClickOutside
    },
    props: {
        // eslint-disable-next-line vue/require-prop-types
        value: {},
        valueSelect: [String, Number],
        prependIcon: { type: String },
        items: { type: Array },
        label: { type: String },
        errors: { type: String },
        escape: { type: Boolean },
        name: {
            required: true,
            type: String
        },
        maxHeight: {
            type: String,
            required: false
        },
        inputAdditionalClass: {
            type: String,
            required: false
        },
        placeholder: {
            type: String,
            required: false
        }
    },
    data() {
        return {
            openListItem: false,
            filteredItems: [],
            search: ''
        };
    },
    computed: {
        setValue() {
            const isExist = this.items.some((el) => el.value === this.search);
            if (isExist) {
                this.$emit('input', this.search);
                return this.items.find((element) => element.value === this.search).text;
            }
            if (this.escape) {
                return ''
            }
            return this.search;
        },
        filteredList() {
            if (this.search === this.value) {
                return this.items
            }
            return this.items.filter((item) => item.text.toLowerCase().includes(this.search.toLowerCase()));
        },
        validationError() {
            return this.errors ? this.errors : '';
        },
        inputClasses() {
            return {
                'form-select__input': true,
                hasIcon: !!this.prependIcon
            }
        }
    },
    watch: {
        value(value) {
            if (!value) {
                this.search = ''
            } else {
                this.search = value
            }
        }
    },
    created() {
        this.setInitValue();
    },
    methods: {
        selectItem(value) {
            this.$emit('input', value);
            this.search = value
            this.openListItem = false;
        },
        onInputChanged(event) {
            this.openListItem = true;
            this.search = event.target.value;
            if (this.search === '') {
                this.$emit('input', '');
            }
        },
        setInitValue() {
            this.search = this.valueSelect ? this.valueSelect : '';
        },
        clickOutside() {
            this.openListItem = false
        }
    }
};
</script>

<style lang="scss">
.form-select {
    outline: none;
    // border: 1px solid #27d91f ;
    border: $input-border;
    -webkit-border-radius: $input-border-radius;
    -moz-border-radius: $input-border-radius;
    border-radius: $input-border-radius !important;
    max-height: 52px!important;
    width: 100%;

    &.success-border {
        border: $input-border-success;
    }
    &.grey-border {
        border: $input-border;
    }
    &__icon {
        position: absolute;
        top: 10px;
        left: 12px;
    }

    &__fieldset {
        position: relative;
        border: none ;
        outline: none;

    }

    &__legend {
        display: table;
        color: #b3bbc6;
        caret-color: #b3bbc6;
        padding: 2px 5px 2px 5px;
        font-size: 80%;
        position: absolute;
        left: 6px;
        top: -8px;
        background-color: white;
        border: none;
    }

    &__input {
        box-sizing: border-box;
        display: inline-block;
        padding: 0 35px 0 10px;
        width: 100%;
        cursor: pointer;
        border: none ;
        outline: none;
        -webkit-border-radius: $input-border-radius;
        -moz-border-radius: $input-border-radius;
        border-radius: $input-border-radius;
        font-family: $font-family-base, sans-serif ;
        font-size: 14px;
        max-height: 48px;
        height: 48px;
        &.hasIcon{
            padding-left: 40px;
        }
    }

    &__list {
        overflow-y: scroll;
        max-height: 270px;
        background-color: white;
        position: absolute;
        z-index: 10;
        top: 55px;
        text-align: left;
        width: 100%;
        margin: 0;
        padding: 0;
        -webkit-box-shadow: 0 5px 5px -3px rgba(0, 0, 0, .2), 0 8px 10px 1px rgba(0, 0, 0, .14), 0 3px 14px 2px rgba(0, 0, 0, .12);
        box-shadow: 0 5px 5px -3px rgba(0, 0, 0, .2), 0 8px 10px 1px rgba(0, 0, 0, .14), 0 3px 14px 2px rgba(0, 0, 0, .12);
        border-radius: 4px;
    }

    &__list-item {
        padding: 15px;
        background-color: #fff;
        margin-bottom: 1px;
        cursor: pointer;
        &:hover {
            background-color: lightgrey;
            //color: #FFF;
        }
    }
    &__error {
        display: inline;
        width: auto;
        text-align: left;
        color: $error-color;
        caret-color: $error-color;
        font-size: 12px;
        min-height: 14px;
        position: absolute;
        left: 13px;
        bottom: -7px;
        background: white;
        padding: 0 3px;
    }
}
</style>
