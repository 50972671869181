<template>
    <div
        class="support-messenger__wrapper"
    >
        <div
            v-if="!showMessenger && showPlaceholder"
            class="support-messenger__placeholder"
            :class="{'support-messenger__placeholder--is-auth' : getterIsAuth}"
            @click="showMessenger = !showMessenger"
        >
            <p>Let's chat</p>
            <span>1</span>
        </div>
        <div
            class="support-messenger__button"
            :class="{'support-messenger__button--is-auth' : getterIsAuth}"
            @click="showMessenger = !showMessenger"
        >
            <img
                v-if="!showMessenger"
                src="@/assets/img/support_agent.jpg"
                alt="support agent"
                class="support-messenger__image"
            >
            <ChevronDown v-else />
        </div>
        <transition
            mode="out-in"
            name="slide-y"
        >
            <supportMessenger
                v-if="showMessenger"
                class="messenger_iframe"
                @closeMessenger="showMessenger = false"
            />
        </transition>
    </div>
</template>

<script>
import { eventBus } from '@/helpers/event-bus/'
import { mapGetters } from 'vuex'
import ChevronDown from 'mdi-vue/ChevronDown.vue'
import supportMessenger from '@/views/supportMessenger/index.vue'

const file = require('@/assets/sounds/correct-answer.wav')

export default {
    components: {
        ChevronDown,
        supportMessenger
    },
    data() {
        return {
            showMessenger: false,
            showPlaceholder: true
        }
    },
    computed: {
        ...mapGetters('client', [
            'getterIsAuth'
        ])
    },
    watch: {
        showMessenger() {
            if (this.showPlaceholder) {
                this.showPlaceholder = false
            }
        }
    },
    mounted() {
        window.addEventListener('message', (event) => {
            if (event.origin !== this.shopUrl) return;
            if (event.data === 'closeChat') {
                this.showMessenger = false
            }
        }, false);

        eventBus.$on('openMessenger', async (id) => {
            this.playSound()
            this.$nextTick(() => {
                eventBus.$emit('openChat', id)
            })
        });
    },
    methods: {
        playSound() {
            this.showMessenger = true
            const snd = new Audio(file);
            snd.play();
        }
    }
}
</script>

<style lang="scss">
    @import "~@/assets/styles/common/components/_support-messenger";
    .messenger_iframe{
        position: fixed;
        bottom: 150px;
        right: 40px;
        width: 400px;
        max-width: 100%;
        height: 700px;
        z-index: 9999;
        border-radius: 15px;
        @media all and (max-width: 576px), (max-height: 880px) {
            bottom: 0;
            right: 0;
            width: 100%;
            height: 100vh;
            max-height: 100%;
        }
    }
</style>
