<template>
    <div
        class="support-messenger"
    >
        <SupportMessengerHeader
            :type="headerType"
            :show-avatars="Boolean(list.length)"
            @goToBack="goToList"
            @closeMessenger="closeMessenger()"
        />

        <div
            v-if="!isLoading"
            class="support-messenger_body"
            :class="{
                [`support-messenger_body--header-${headerType}`]: true,
                'support-messenger_body--scroll': list.length > 0 && (state === 'create' || state === 'list'),
                'support-messenger_body--scroll-visible': list.length === 0 && state !== 'create',
                'support-messenger_body--chat': state === 'chat',
            }"
        >
            <transition
                mode="out-in"
                name="bounce"
            >
                <SupportMessengerList
                    v-if="state === 'list' && list.length"
                    :list="list"
                    @goToChat="goToChat"
                />
            </transition>
            <transition
                mode="out-in"
                name="bounce"
            >
                <SupportMessengerCreate
                    v-if="state === 'create'"
                    :ticket-creating="ticketCreating"
                    @createTicket="createTicket"
                />
            </transition>
            <transition
                mode="out-in"
                name="bounce"
            >
                <SupportMessengerEmpty
                    v-if="state === 'list' && !list.length"
                    @goToCreate="goToCreate"
                />
            </transition>
            <transition
                mode="out-in"
                name="bounce"
            >
                <SupportMessengerChat
                    v-if="state === 'chat'"
                    :messages="messages"
                    :ticketid="currentTicket"
                    @readMessages="readMessages"
                />
            </transition>
        </div>

        <div
            v-if="state === 'list' && list.length"
            class="support-messenger_footer"
        >
            <button
                class="btn-base btn-main"
                @click="state = 'create'"
            >
                New conversation
            </button>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Api from '@/helpers/api/index.js'
import { eventBus } from '@/helpers/event-bus/'
import {
    createTicket, getGuestTicket, setGuestTicket, getGuestTicketFromStorage, removeGuestTicketFromStorage
} from '@/services/support'
import SupportMessengerHeader from '@/components/support/supportMessenger/SupportMessengerHeader'
import SupportMessengerList from '@/components/support/supportMessenger/SupportMessengerList'
import SupportMessengerCreate from '@/components/support/supportMessenger/SupportMessengerCreate'
import SupportMessengerEmpty from '@/components/support/supportMessenger/SupportMessengerEmpty'
import SupportMessengerChat from '@/components/support/supportMessenger/SupportMessengerChat'
import { mapSupportFields } from '@/store/modules/support';

const updateInterval = 6 * 10 * 1000
export default {
    components: {
        SupportMessengerHeader,
        SupportMessengerList,
        SupportMessengerCreate,
        SupportMessengerEmpty,
        SupportMessengerChat
    },
    data() {
        return {
            isLoading: true,
            ticketCreating: false,
            list: [],
            intervalId: null
        }
    },
    computed: {
        ...mapGetters('client', [
            'getterIsAuth'
        ]),
        ...mapSupportFields([
            'state',
            'currentTicket'
        ]),
        headerType() {
            return this.state === 'create' || this.state === 'chat' ? 'small' : 'normal'
        },
        messages() {
            if (this.state === 'chat') {
                const ticket = this.list.find((item) => item.ticketid === this.currentTicket)
                return ticket.messages
            }
            return []
        },
        getUnreadCounter() {
            return this.list.reduce((acc, curr) => {
                if (curr.counters?.unread_messages_count) {
                    acc += curr.counters.unread_messages_count
                }
                return acc
            }, 0);
        }
    },
    watch: {
        async getterIsAuth() {
            clearTimeout(this.intervalId)
            this.state = 'list'
            this.currentTicket = ''
            this.list = []
            await this.getTickets()
            this.intervalId = setTimeout(async () => {
                await this.liveUpdate()
            }, updateInterval);
        }
    },
    async created() {
        this.state = 'list'
        await this.getTickets()
        this.intervalId = setTimeout(async () => {
            await this.liveUpdate()
        }, updateInterval);
    },
    mounted() {
        eventBus.$on('openChat', async (id) => {
            await this.goToChat(id)
        });
    },
    methods: {
        async goToList() {
            this.state = 'list'
            this.currentTicket = ''
            await this.getTickets()
        },
        goToCreate() {
            this.state = 'create'
            this.currentTicket = ''
        },
        goToChat(id) {
            this.state = 'chat'
            this.currentTicket = id
        },
        closeMessenger() {
            window.parent.postMessage('closeChat', '*');
            this.$emit('closeMessenger')
        },
        async getTickets() {
            this.isLoading = true
            if (!this.getterIsAuth) {
                const requests = []
                getGuestTicketFromStorage().forEach((ticket) => {
                    requests.push(getGuestTicket({ ticketid: ticket.ticketid, hash: ticket.hash }))
                });
                const removeIndexes = []
                await Promise.allSettled(requests)
                    .then((results) => results.forEach((result, index) => {
                        if (result.status === 'fulfilled') {
                            const findIn = this.list.findIndex((item) => item.ticketid === result.value.ticketid)
                            if (findIn !== -1) {
                                this.list.splice(findIn, 1, result.value)
                            } else {
                                this.list.push(result.value)
                            }
                        } else {
                            removeIndexes.push(index)
                        }
                    }))
                    .finally(() => {
                        this.isLoading = false
                    })
                removeGuestTicketFromStorage(removeIndexes)
            } else {
                try {
                    const { data } = await Api.get('/api/support/auth/list-by-preset', {
                        mode: 'list',
                        page: 1,
                        per_page: 10,
                        preset: 'Tickets_Open'
                    })
                    this.list = data.data
                } catch (error) {
                    eventBus.$emit('showSnackBar', error, 'error');
                } finally {
                    this.isLoading = false
                }
            }
        },
        async liveUpdate() {
            if (this.getterIsAuth) return
            const requests = []
            getGuestTicketFromStorage().forEach((ticket) => {
                requests.push(getGuestTicket({ ticketid: ticket.ticketid, hash: ticket.hash }))
            });
            const removeIndexes = []
            await Promise.allSettled(requests)
                .then((results) => results.forEach((result, index) => {
                    if (result.status === 'fulfilled') {
                        const findIn = this.list.findIndex((item) => item.ticketid === result.value.ticketid)
                        if (findIn !== -1) {
                            this.list.splice(findIn, 1, result.value)
                        } else {
                            this.list.push(result.value)
                        }
                    } else {
                        removeIndexes.push(index)
                    }
                }))
            removeGuestTicketFromStorage(removeIndexes)
            this.intervalId = setTimeout(async () => {
                await this.liveUpdate()
            }, updateInterval);
        },
        async createTicket(payload) {
            try {
                this.ticketCreating = true
                const data = await createTicket(payload, this.getterIsAuth)
                this.currentTicket = data.ticketid
                if (!this.getterIsAuth) {
                    setGuestTicket(data)
                }
                clearTimeout(this.intervalId)
                await this.getTickets()
                this.state = 'chat'
            } catch (error) {
                eventBus.$emit('showSnackBar', error, 'error');
            } finally {
                this.ticketCreating = false
            }
        },
        readMessages(ticketid) {
            const findIn = this.list.findIndex((item) => item.ticketid === ticketid)
            this.list.splice(findIn, 1, {
                ...this.list[findIn],
                counters: {
                    unread_messages_count: 0
                }
            })
        }
    }
}
</script>

<style lang="scss">
    @import "~@/assets/styles/common/components/_support-messenger";
</style>
