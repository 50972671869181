<template>
    <div
        v-if="message.author"
        class="message"
        :class="{
            'message--client': isCurrentUserMessage,
            'message--writer': message.author.type === 'Writer',
            'message--admin': message.author.type === 'Admin',
        }"
    >
        <div
            v-if="!isCurrentUserMessage"
            class="message_avatar"
        >
            <avatar
                :avatar-fallback="message.author.avatar_fallback"
                :avatar-url="message.author.avatar_url"
                :nickname="message.author.name"
            />
        </div>
        <div
            class="message_content"
            :class="{'message_details--new': message.is_unread}"
        >
            <header
                v-if="!isCurrentUserMessage"
                class="message_content__header"
            >
                <p>
                    {{ message.author.name }} ({{ message.author.type }} {{ message.author.id }})
                </p>
            </header>
            <main class="message_content__body">
                <div
                    v-if="message.type === 'revision'"
                    class="message--revision"
                >
                    Revision request
                </div>
                <div class="message_details">
                    <span v-html="messageDetails" />
                </div>
                <span class="message_datetime">
                    <span>{{ message.created_at | moment_from }} ago</span>
                    <span>{{ message.created_at | moment_short_text }}</span>
                </span>
            </main>
        </div>
    </div>
</template>

<script>
// Mixins
import filtersMixin from '@/mixins/filtersMixin.js'
// Components
import Avatar from '@/components/common/Avatar.vue'

export default {
    components: {
        Avatar
    },
    mixins: [
        filtersMixin
    ],
    props: {
        message: {
            type: Object,
            required: true
        },
        nickname: {
            type: String
        }
    },
    computed: {
        isCurrentUserMessage() {
            return this.message.author.type === 'Client' || this.message.author.type === 'Lead'
        },
        messageDetails() {
            return this.message.details.replaceAll('<', '').split('\n').join('<br>')
        }
    }
}
</script>

<style lang="scss">
    .message{
        display: flex;
        margin: 20px 0;
        max-width: 50%;
        min-width: 320px;
        &--client{
            margin-left: auto;
            .message_details{
                background: #daf1ff;
            }
        }
        &_avatar{
            margin-right: 20px;
            .avatar-img {
                @include media480max {
                    height: 40px;
                    width: 40px;
                }
                @include media380max {
                    height: 30px;
                    width: 30px;
                }
            }
        }
        &_content{
            margin-right: 10px;
            &__header,
            .message--revision{
                display: flex;
                justify-content: space-between;
                margin-bottom: 5px;
                color: #a8aaae;
                font-size: 14px;
                @include media380max {
                    font-size: 10px;
                }
            }
        }
        &_details{
            display: flex;
            flex-direction: column;
            padding: 20px;
            background: rgba($color: #f0f0f0, $alpha: 1);
            border-radius: 10px;
            position: relative;
            min-width: 320px;
            line-height: 1.5;
            @include media480max {
                min-width: 230px;
                width: 230px;
            }
            @include media380max {
                min-width: 200px;
                width: 200px;
                padding: 15px;
                font-size: 12px;
            }
        }
        &_datetime{
            margin-top: 5px;
            display: flex;
            justify-content: space-between;
            font-size: 12px;
            color: #a8aaae;
            @include media380max {
                font-size: 10px;
            }
        }
    }
</style>
