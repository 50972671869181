<template lang="html">
    <div
        class="bc-wrapper"
        :class="settings.additionalClass ? settings.additionalClass : 'bc-horizontal'"
    >
        <div class="wrapper">
            <div class="breadcrumbs-wrapper">
                <div class="breadcrumbs-info">
                    <ul class="breadcrumbs breadcrumbs-item">
                        <template v-for="(step, index) in settings.steps">
                            <li
                                :key="index"
                                class="breadcrumbs--item"
                                :class="settings.steps.length - 1 === index ? 'bc-current' : ''"
                            >
                                <a
                                    v-if="isLinkToContent(step.url)"
                                    :href="step.url"
                                >{{ step.title }}</a>
                                <router-link
                                    v-else
                                    :to="step.url"
                                >
                                    {{ step.title }}
                                </router-link>
                                <ChevronRightIcon v-if="settings.steps.length - 1 != index" />
                            </li>
                        </template>
                    </ul>

                    <h1 class="page-title breadcrumbs-item">
                        <span class="title">
                            <span
                                class="p-semibold-weight"
                                v-html="settings.title"
                            />
                            <span
                                v-if="$route.name.includes('order_') && !isOrderForm"
                                class="order-details-header"
                            >
                                <span class="order-details-header__type">{{ settings.typeProject }}</span>
                                <span class="order-details-header__title">«{{ settings.titleProject }}»</span>
                            </span>
                            <span
                                v-if="totalBalance"
                                class="bc_info_balance"
                            >{{ totalBalance | money }}</span>
                            <span
                                v-if="totalOrders"
                                class="bc_info_balance"
                            >{{ totalOrders | number }}</span>
                            <slot
                                class="bc_info_balance"
                                name="total"
                            />
                        </span>
                    </h1>
                    <div class="breadcrumbs-row">
                        <p
                            v-if="settings.excerpt"
                            class="bc-wrapper-excerpt breadcrumbs-item"
                        >
                            {{ settings.excerpt }}
                        </p>
                        <slot name="rightSide" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ChevronRightIcon from 'mdi-vue/ChevronRight'
import filtersMixin from '@/mixins/filtersMixin.js'

export default {
    name: 'Breadcrumbs',
    components: {
        ChevronRightIcon
        // ReportAProblem
        // Bell
    },
    mixins: [
        filtersMixin
    ],
    props: {
        settings: {
            type: Object,
            required: true
        },
        showDetails: {
            type: Boolean,
            default: false
        },
        totalBalance: {
            type: Number,
            default: 0
        },
        totalOrders: {
            type: Number,
            default: 0
        },
        hidereport: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        isOrderForm() {
            return this.$route.name.includes('order_new') || this.$route.name.includes('order_edit')
        }
    },
    methods: {
        isLinkToContent(val) {
            return val.includes(process.env.VUE_APP_API_CONTENT)
        },
        redirectToTheId() {
            this.$router.push({
                name: 'support_form'
            })
        }
    }
}
</script>

<style lang="scss">
.breadcrumbs--item {
    display: flex;
    align-items: center;
}
.bc-wrapper {
    .wrapper {
        flex-wrap: wrap;
        .breadcrumbs-item{
            &:nth-child(2) {
                display: flex;
                justify-content: space-between;
            }
            width: 100%;
        }
    }
}
@media screen and (max-width: 600px){
    .bc-wrapper {
        padding-bottom: 100px;
        .wrapper {
            .breadcrumbs-item {
                &:nth-child(2) {
                    flex-wrap: wrap;
                    padding-right: 0 !important;
                    .report-wrapper {
                        display: flex;
                        justify-content: flex-start;
                        width: 100%;
                    }
                }
            }
        }
    }
}

.report-a-problem{
    display: flex;
    align-items: flex-end;
    cursor: pointer;
    span {
        font-size: 16px;
        font-weight: 300;
        margin-left: 5px;
    }
}
.breadcrumbs-row{
    display: flex;
    flex-wrap: wrap;
    .breadcrumbs-item{
        margin-right: auto;
    }
    @media all and (max-width: 1079px) {
        .breadcrumbs-btns{
            margin-top: 20px;
            width: 100%;
        }
    }
}

.order-details-header{
    display: flex;
    flex-direction: column;
    font-size: 24px;
    &__type {
        @media all and (max-width: 550px) {
            max-width: 300px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }
    &__title {
        line-height: 1.5;
        font-weight: 300;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        text-overflow: ellipsis;
        overflow: hidden;
        width: 100%;
    }
}

</style>
