<template>
    <div class="phone_wrapper">
        <phone-number-valid
            v-if="verified"
            :phone-code="country.value"
            :phone="phone_number"
        />
        <div v-else>
            <phone-number-field
                v-if="!started"
                ref="phone-number-field"
                v-model="phone"
            />
            <phone-number-pin v-else />
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import { mapPhoneFields } from '@/store/modules/phone'
import PhoneNumberValid from './components/PhoneNumberValid'
import PhoneNumberField from './components/PhoneNumberField'
import PhoneNumberPin from './components/PhoneNumberPin'

// VUEX

export default {
    name: 'PhoneNumber',
    components: {
        PhoneNumberValid,
        PhoneNumberField,
        PhoneNumberPin
    },
    data: () => ({
        phone: {}
    }),
    computed: {
        ...mapState('phone', {
            started: 'started',
            verified: 'verified'
        }),
        ...mapPhoneFields([
            'form_data.country',
            'form_data.phone_number'
        ])
    },
    created() {
        this.$store.commit('phone/SET_DEFAULT')
    },
    methods: {
        validate() {
            this.$refs['phone-number-field'].$v.$touch()
        }
    }
}

</script>

<style lang="scss">
.phone__row {
    align-items: flex-start;
    display: flex;
    .form-select {
        width: 120px;
        min-width: 120px;
        max-width: 120px;
        margin-right: -1px !important;
        border: none !important;
        &__list {
            width: 285px;
        }
    }
    .form-input {
        &__input {
            border-radius: 0 $input-border-radius $input-border-radius 0;
        }
        &__pin {
            .form-input__input {
                font-size: 14px;
                margin: 0;
                border-radius: $input-border-radius;
            }
        }
    }
    &-verify.btn-base_disabled {
        color: rgba(0, 0, 0, 0.26) !important;
    }
    &-verify {
        .loader {
            color: $white;
        }
        &.disabled {
            border: 1px solid rgba(0, 0, 0, 0.12) !important;
            background-color: rgba(0, 0, 0, 0.12);
            color: #1e2838 !important;
            pointer-events: none;
        }
        &:hover {
            background-color: #fef3ed;
            cursor: pointer;
        }
    }
    &-pincode {
        label {
            font-size: 14px !important;
        }
    }
    &-pin {
        height: 50px !important;
        border: none;
        margin: 0 10px;
        color: $white !important;
        font-family: $font-family-base, sans-serif;
        font-size: 14px;
        font-weight: 700;
        text-transform: uppercase;
        background-image: $main-gradient;
        border-radius: $input-border-radius;
        width: auto;
        box-shadow: none;
    }
    &-submit-btn {
        max-width: calc(25% - 5px);
        margin-left: 5px;
        box-shadow: none;
    }
    &-reset-btn {
        max-width: calc(25% - 5px);
        margin-left: 5px;
        min-width: 92px;
        border-radius: $btn-base__border-radius;
        background: $white;
        font-size: 0.875rem;
        height: 48px;
        // border: 1px solid #d3d9e2;
        // color: rgba(0, 0, 0, 0.87);
        // &:hover {
        //     cursor: pointer;
        //     background: rgba(0, 0, 0, 0.12);
        // }
    }
}
.phone-success-verification {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 10px 20px 10px 20px;
    background: #e7ffe4;
    color: $font-color-main;
    font-size: 16px;
    font-weight: 400;
    border-radius: $input-border-radius;
    position: relative;
    overflow: hidden;
    margin: 0 0 30px 0;
    &__num {
        display: flex;
        align-items: center;
    }
    &__icon {
        width: 30px;
        height: 30px;
        background: #59c74d;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        svg {
            color: #fff;
        }
    }
    &:before {
        content: '';
        width: 4px;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        background: #59c74d;
    }
}
.phone-verification {
    position: relative;
    width: 100%;
    height: 52px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    color: #333;
    font-size: 16px;
    font-weight: 500;
    line-height: 28px;
    margin: 5px 0 30px 0;
    &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 4px;
        height: 100%;
        background-color: $second-color;
    }
    &__num {
        display: flex;
        align-items: center;
        height: 100%;
        padding: 0 0 0 30px;
        background-color: #fff5f1;
        flex-grow: 2;
    }
    &__btn {
        margin-left: 10px;
        height: 100%;
        width: 230px;
        a {
            box-shadow: none;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100% !important;
            padding: 0;
            width: 160px;
        }
    }
    &__switch {
        display: flex;
        height: 100%;
        align-items: center;
        &-text {
            margin-right: 20px;
        }
    }
}
.phone__form-col_btn {
    display: flex;
    button {
        height: 50px !important;
    }
}
.profile-info__form .flag-icon-background {
    background-size: contain;
    background-position: 50%;
    background-repeat: no-repeat;
}
.flag-icon {
    background-size: contain;
    background-position: 50%;
    background-repeat: no-repeat;
    position: relative;
    display: inline-block;
    width: 1.33333333em;
    line-height: 1em;
}
.flag-icon:before {
    content: '\00a0';
}
.country-code {
    color: rgba(0, 0, 0, 0.87);
    margin-left: 0 !important;
}

.profile-info .phone__row {
    width: 50%;
    margin: 0 auto;
}
@media all and (max-width: 1200px) {
    .profile-info .phone__row {
        width: 70%;
    }
}

@media all and (max-width: 576px) {
    .phone__form-col_btn {
        margin: 33px 0 -5px 0;
    }
    .profile-info .phone__row {
        width: 100%;
    }
}

@media all and (max-width: 480px) {
    .phone-verification {
        &__num {
            padding: 0 0 0 10px;
        }
        &__btn {
            margin-left: 10px;
            a {
                width: 80px;
                padding: 0 !important;
            }
        }
    }
}
</style>
