import Vue from 'vue'
import { io } from 'socket.io-client';
import Auth from '@/helpers/auth/index.js'

class Socket {
    constructor() {
        this.socket = null
        this.isConnected = false
    }

    connect() {
        if (!process.env.VUE_APP_SOCKET_SERVER) {
            console.error('Sockets are not connected. VUE_APP_SOCKET_SERVER is empty')
            return
        }
        this.socket = io(process.env.VUE_APP_SOCKET_SERVER, {
            query: {
                domain: process.env.VUE_APP_SOCKET_DOMAIN
            },
            auth: {
                token: Auth.getToken()
            }
        });
        this.isConnected = true

        this.on('disconnect', (reason) => {
            console.warn(reason);
        });
    }

    reconnect() {
        this.isConnected = false
        this.emit('forceDisconnect');
        this.connect()
        this.isConnected = true
    }

    emit(name, payload) {
        this.socket.emit(name, payload)
    }

    on(name, callback) {
        this.socket.on(name, callback)
    }
}

export default {
    install: () => {
        const socket = new Socket()
        Vue.prototype.$socket = socket
        Vue.$socket = socket
    }
}
