<template>
    <div
        v-click-outside="clickOutside"
        class="form-select-writers"
    >
        <preferred-writers-account-settings-modal
            :icon="icon"
            :title="title"
            :writers="value"
            @deleteWriter="deleteWriter"
        />
        <fieldset
            :class="[openListItem ? 'form-select-writers__fieldset active' : 'form-select-writers__fieldset', {'error-border': !styles}, {'success': selectedWriters.length > 0}]"
            @click.self="toggleList"
        >
            <legend class="form-select-writers__legend">
                {{ label }}
            </legend>
            <div class="form-select-writers__selected-block">
                <ul
                    class="form-select-writers__selected-list"
                    @click.self="toggleList"
                >
                    <li
                        v-for="(item) in value.slice(0, writersArrayLength)"
                        :key="item.id"
                        class="form-select-writers__selected-item"
                        :class="{'form-select-writers__selected-item--li': value.length > writersArrayLength }"
                    >
                        <div
                            class="form-select-writers__avatar form-select-writers__avatar--small"
                            :class="{ 'error-border': !styles }"
                        >
                            <Avatar
                                :avatar-url="item.avatar_url"
                                :avatar-fallback="item.avatar_fallback"
                                :nickname="item.name"
                                :email="item.email"
                                :class-name="'avatar-list'"
                            />
                        </div>
                        <span>
                            <span
                                v-if="item.name"
                                class="form-select-writers__name"
                            >
                                {{ item.name }}
                            </span>
                            <span v-else>{{ item.id }}</span>
                        </span>
                        <img
                            class="form-select-writers__btn-icon"
                            :class="[{ 'error': !styles }]"
                            src="@/assets/img/svg/cross.svg"
                            @click="deleteWriter(item.id)"
                        >
                    </li>
                </ul>
                <span
                    v-if="!value.length"
                    class="form-select-writers__placeholder"
                    @click.self="toggleList"
                >
                    Select {{ label.toLowerCase() }}
                </span>
                <transition name="fade">
                    <ul
                        v-if="openListItem"
                        ref="list"
                        class="form-select-writers__list"
                        @scroll="loadMore"
                    >
                        <li>
                            <div class="form-select-writers__search">
                                <Magnify class="form-select-writers__icon form-select-writers__icon--prepend" />
                                <input
                                    ref="searchInput"
                                    v-model="search"
                                    autocomplete="off"
                                    class="form-select-writers__input"
                                    placeholder="Enter a writer's nick or ID"
                                    type="text"
                                >
                                <span
                                    v-if="search.length > 0"
                                    @click.stop="clearSearchField()"
                                >
                                    <Close class="form-select-writers__icon form-select-writers__icon--append" />
                                </span>
                            </div>
                        </li>
                        <li
                            v-for="writer in filteredWriters"
                            :key="writer.id"
                            @click="selectWriter(writer)"
                        >
                            <!-- <div class="form-select-writers__avatar" :style="{'background-image': writer.avatar}"></div> -->
                            <div
                                class="form-select-writers__list-item"
                                :class="{'form-select-writers__list-item--selected': isWriterSelected(writer)}"
                            >
                                <div class="form-select-writers__list-item__avatar">
                                    <Avatar
                                        :avatar-url="writer.avatar_url"
                                        :avatar-fallback="writer.avatar_fallback"
                                        :nickname="writer.name"
                                        :email="writer.email"
                                    />
                                    <span
                                        class="form-select-writers__list-item__avatar--status"
                                        :class="writer.is_online ? 'online' : 'offline'"
                                    />
                                </div>
                                <div class="form-select-writers__info">
                                    <div>
                                        <span class="form-select-writers__info-title">{{ writer.name }}</span>
                                        <span
                                            v-if="!writer.is_online && writer.last_seen"
                                            class="form-select-writers__info__lastseen"
                                        >
                                            Last seen: {{ writer.last_seen | moment_from }} ago
                                            <span
                                                class="form-select-writers__info__lastseen-tooltip tooltip-zoom"
                                                :data-tooltip-attribute="writer.last_seen | moment_short_text"
                                            >
                                                i
                                            </span>
                                        </span>
                                    </div>
                                    <span
                                        v-if="writer.statistics"
                                        class="form-select-writers__info-subtitle"
                                    >
                                        <span class="form-select-writers__info-subtitle-info-id">ID: <b>{{ writer.id }}</b></span>
                                        <span
                                            v-if="writer.statistics.orders_total"
                                            class="form-select-writers__info-subtitle-info"
                                        >
                                            <b>{{ writer.statistics.orders_total | number }}</b> assignments
                                        </span>
                                        <span
                                            v-if="writer.statistics.rank_all"
                                            class="form-select-writers__info-subtitle-info"
                                        >
                                            <b>{{ writer.statistics.rank_all | percentWriterRank }}</b> success rate
                                        </span>
                                    </span>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div
                                v-if="filteredWriters.length === 0"
                                class="form-select-writers__list-item"
                            >
                                No writer was found
                            </div>
                        </li>
                    </ul>
                </transition>
            </div>
        </fieldset>
    </div>
</template>

<script>
import { debounce } from 'lodash'
import ClickOutside from 'vue-click-outside'
import Close from 'mdi-vue/Close.vue';
import Magnify from 'mdi-vue/Magnify.vue';
import Avatar from '@/components/common/Avatar.vue';
import PreferredWritersAccountSettingsModal from '@/components/writers/PreferredWritersAccountSettingsModal'

import filtersMixin from '@/mixins/filtersMixin'

export default {
    name: 'FormSelectWriters',
    components: {
        Avatar,
        Close,
        Magnify,
        PreferredWritersAccountSettingsModal
    },
    directives: {
        ClickOutside
    },
    mixins: [
        filtersMixin
    ],
    props: {
        writers: { type: Array },
        label: { type: String },
        value: { type: Array },
        // eslint-disable-next-line vue/require-prop-types
        styles: {},
        // eslint-disable-next-line vue/require-prop-types
        disabled_items: { Array },
        name: {
            required: true,
            type: String
        },
        icon: {
            type: String
        },
        title: {
            type: String
        },
        writersArrayLength: {
            type: Number
        }
    },
    data() {
        return {
            openListItem: false,
            selectedWriters: [],
            search: ''
        };
    },
    computed: {
        filteredWriters() {
            const itemsWithoutDisabled = this.writers.filter((item) => !this.disabled_items.some((el) => el.id === item.id));
            // const filteredBySearch = itemsWithoutDisabled.filter((item) => this.search.includes(item.id) || item.name.toLowerCase().includes(this.search.toLowerCase()));
            return itemsWithoutDisabled;
        }
    },
    watch: {
        value() {
            this.initWriters()
        },
        search: debounce(function (value) {
            this.$emit('searchWriters', value)
        }, 500)
    },
    created() {
        this.initWriters()
    },
    methods: {
        selectWriter(writer) {
            const found = this.selectedWriters.find((element) => element.id === writer.id);
            // const foundPreferred = this.preferred_writers.find((element) => element.id === value.id);
            if (!found) {
                this.selectedWriters.push({ ...writer })
            } else {
                this.selectedWriters = this.selectedWriters.filter((element) => element.id !== writer.id)
            }

            // this.selectedWriters.push({ ...writer })
            this.$emit('input', [...this.selectedWriters]);
            // writer.active ? writer.active = false : writer.active = true
        },
        deleteWriter(id) {
            this.value.forEach((item, index) => {
                if (item.id === id) {
                    this.selectedWriters.splice(index, 1)
                    this.$emit('input', [...this.selectedWriters]);
                }
            })
        },
        initWriters() {
            this.selectedWriters = [...this.value]
        },
        isWriterSelected(writer) {
            return this.value.some((el) => el.id === writer.id)
        },
        clickOutside() {
            this.openListItem = false
        },
        toggleList() {
            this.openListItem = !this.openListItem;
            if (this.openListItem) {
                this.focusOnSearchInput()
            }
        },
        focusOnSearchInput() {
            this.$nextTick(() => {
                this.$refs['searchInput'].focus();
            });
        },
        clearSearchField() {
            this.search = '';
        },
        loadMore() {
            const listElm = this.$refs.list
            const scrollHeightCheck = listElm.scrollTop + listElm.clientHeight >= listElm.scrollHeight - 1
            this.$emit('loadMore', scrollHeightCheck)
        }
    }
}
</script>

<style lang="scss">
.form-select-writers {
    outline: none;
    border-radius: 4px;
    min-height: 50px;

    &__icon {
        position: absolute;
        color: rgba(0,0,0,.54);

        &--prepend{
            top: 12px;
            left: 24px;
        }

        &--append {
            top: 10px;
            right: 12px;
            z-index: 10;
        }
    }

    &__fieldset {
        position: relative;
        outline: none;
        border-radius: $input-border-radius;
        border: $input-border;
        min-height: 48px;

        &.active {
            border: $input-border-success;
        }
    }

    &__legend {
        display: table;
        //color: #b3bbc6;
        padding: 2px 5px 2px 5px;
        //font-size: 80%;
        font-size: 14px;
        position: absolute;
        left: 6px;
        top: -12px;
        background-color: $white;
        border: none;
    }

    &__name{
        white-space: nowrap;
        display: block;
        width: 100px;
        overflow: hidden;
        text-overflow: ellipsis;
        @media screen and (max-width : 375px){
            width: 70px;
        }
    }

    &__input {
        box-sizing: border-box;
        display: inline-block;
        //padding-left: 75px;
        min-width: 100%;
        cursor: pointer;
        border: none;
        border-radius: 0;
        margin-left: 0;
        outline: none;
        font-family: $font-family-base, sans-serif;
        min-height: 46px;
        caret-color: #b3bbc6;
        border-bottom: $input-border;
        user-select: text !important;
        font-size: 14px;
        padding-left: 70px!important;
    }

    &__placeholder {
        position: absolute;
        left: 20px;
        top: 50%;
        transform: translateY(-50%);
        color: rgba(0, 0, 0, .38);
    }

    &__list {
        overflow-y: scroll;
        max-height: 410px;
        background-color: $white;
        position: absolute;
        z-index: 11;
        top: 100%;
        left: 0;
        text-align: left;
        width: 100%;
        margin: 0;
        padding: 0;
        -webkit-box-shadow: 0 5px 5px -3px rgba(0, 0, 0, .2), 0 8px 10px 1px rgba(0, 0, 0, .14), 0 3px 14px 2px rgba(0, 0, 0, .12);
        box-shadow: 0 5px 5px -3px rgba(0, 0, 0, .2), 0 8px 10px 1px rgba(0, 0, 0, .14), 0 3px 14px 2px rgba(0, 0, 0, .12);
        border-radius: 4px;
    }

    &__list-item {
        padding: 15px;
        background-color: $white;
        margin-bottom: 1px;
        cursor: pointer;
        display: flex;
        align-items: center;
        border-bottom: $input-border;

        &:last-child {
            border-bottom: none;
            color: rgba(0,0,0,.54);
        }

        &__avatar{
            margin-right: 16px;
            position: relative;
            .avatar-img {
                width: 40px;
                height: 40px;
            }
            &--status{
                width: 10px;
                height: 10px;
                position: absolute;
                top: 0;
                right: 0;
                border-radius: 50%;
                border: 1px solid #fff;
                @media (max-width: 1024px) {
                    top: 21px;
                    right: 3px;
                }
                &.online {
                    background: #00b55d;
                }
                &.offline {
                    background: #B71A1A;
                }
            }
        }

        .form-select-writers__info {

            &-title {
                color: #000000;
                font-weight: 400;
                //line-height: 1.2;
                margin-bottom: 4px;
            }

            &-subtitle {
                font-size: 12px;
                color: rgba(0,0,0,.9);
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                span:nth-child(2) {
                    margin-top: 5px;
                    margin-bottom: 5px;
                }
            }

            &-subtitle-info-id {
                padding: 5px 0;
                margin-right: 5px;
            }

            &-subtitle-info {
                padding: 5px;
                margin-right: 5px;
                border-radius: 10px;
            }
            &__lastseen {
                margin-left: 5px;
                padding: 5px 10px;
                background: #F2F6FF;
                border-radius: 999px;
                font-size: 14px;
                font-weight: 400;
                &-tooltip{
                    border: 1px solid #1f2939;
                    width: 18px;
                    height: 18px;
                    border-radius: 50%;
                    display: inline-flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 14px;
                    font-weight: 500;
                    transition: all 0.1s ease-in;
                    cursor: pointer;
                }
            }
        }

        &:hover {
            background-color: rgba(230, 230, 230, 0.3);
        }

        &--selected {
            background-color: rgba(230, 230, 230, 1);
            background-image: url("~@/assets/img/svg/check-green.svg");
            background-repeat: no-repeat;
            background-position: center right 25px;
            background-size: 30px;
            @media screen and (max-width : 768px){
                background-position: center right 5px;
                background-size: 20px;
            }
            &:hover {
                background-color: rgba(230, 230, 230, 1);
            }

        }

        &--search {
            padding: 0;
        }
    }

    &__selected-block {
        display: flex;
        align-items: center;
        padding-left: 15px;
        min-height: 46px;
        margin-right: 25px;
    }

    &__selected-list {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        height: 100%;
        height: 50px;
        overflow: hidden;
    }

    &__selected-item {
        position: relative;
        display: flex;
        margin-right: 10px;
        margin-top: 5px;
        margin-bottom: 5px;
        align-items: center;
        background-color: #f4f4f4;
        padding: 7px;
        border-radius: 5px;

        .avatar-wrapper {
            width: 100%;
            height: 100%;

            .avatar-img {
                width: inherit !important;
                height: inherit !important;

                .initials {
                    font-size: 12px;
                }
            }

            .default-avatar  {
                width: 12px;
                height: 12px;
            }
        }
    }

    &__avatar {
        border-radius: 50%;
        width: 40px;
        height: 40px;
        margin-right: 20px;

        &--small {
            width: 24px;
            height: 24px;
            margin-right: 10px;
        }

    }

    &__info {
        display: flex;
        flex-direction: column;
    }
    &__delete-btn {
        margin-left: 10px;
        cursor: pointer;
        color: #4caf50;
    }

    &__btn-icon {
        //height: 12px;
        //width: 12px;
        margin-left: 10px;
        cursor: pointer;
    }
}

.form-select-writers__delete-btn.error {
    color: red;
}

.form-select-writers__fieldset.success {
    border: 1px solid #27d91f;
}

.fade-enter-active, .fade-leave-active {
    transition: opacity .3s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
}

@media screen and (max-width : 768px){

    .form-select-writers {

        &__input {
            padding-left: 0;
        }
    }
}

.form-select-writers__selected-item--li:last-child{
    margin-right: 20px !important;
    &:after {
        content: '...';
        right: -20px;
        position: absolute;
    }
}
</style>
