<template>
    <div
        class="support-messenger_chat"
    >
        <div class="support-messenger_chat_wrapper">
            <message
                v-for="(messageData, message_index) in messages"
                :key="message_index"
                :message="messageData"
            />
        </div>

        <transition
            mode="out-in"
            name="slide-y"
        >
            <BotAnimated v-if="showBot" />
        </transition>

        <div class="support-messenger_chat_form">
            <p
                class="support-messenger_typing-message"
            >
                {{ typingMessage }}
            </p>
            <form-textarea
                v-model="message"
                class="form-textarea__input-messages"
                style="margin-right: 10px"
                legend="Message"
                type="text"
                name="message"
                :disabled="isLoading"
                :field.sync="message"
                :errors="validationMessage($v.message)"
                @input.native="$v.message.$touch()"
                @blur.native="$v.message.$touch()"
                @keyup.native="keyListner($event)"
            />

            <custom-button
                class="btn-base btn-main chat-actions-btn btn-reply"
                :loading="isLoading"
                @on-btn-click="sendMessage()"
            >
                <replyIcon v-if="!isLoading " />
            </custom-button>
        </div>
    </div>
</template>

<script>
import { debounce } from 'lodash'
import { mapGetters } from 'vuex'
import { eventBus } from '@/helpers/event-bus/'
// Validation
import { validationMixin } from 'vuelidate'
import { validationMessage } from 'vuelidate-messages'
import { required } from 'vuelidate/lib/validators'
import { formMessages } from '@/validation/support/Messages'
import {
    sendMessage, readTicket, getSupportMessages, getGuestTicketFromStorage
} from '@/services/support'
// Components
import Message from '@/components/common/Chat/Message.vue'
import BotAnimated from '@/components/support/supportMessenger/BotAnimated.vue'
import replyIcon from '@/components/icons/reply.vue'

export default {
    name: 'SupportMessengerChat',
    components: {
        Message,
        replyIcon,
        BotAnimated
    },
    mixins: [
        validationMixin
    ],
    props: {
        ticketid: {
            type: String,
            required: true
        }
    },
    validations: {
        message: { required }
    },
    data() {
        return {
            message: '',
            messages: [],
            isLoading: false,
            timeout: false,
            socket: null,
            typing: false,
            typingMessage: ''
        }
    },
    computed: {
        ...mapGetters('client', [
            'getterIsAuth',
            'getterMainClientInfo'
        ]),
        showBot() {
            return this.messages.length === 1 && this.timeout
        },
        getHash() {
            if (this.getterIsAuth) return null
            const ticket = getGuestTicketFromStorage().find((item) => item.ticketid === this.ticketid)
            return ticket.hash
        }
    },
    watch: {
        message() {
            this.typing = true
            this.userTyping(this)
        },
        typing(newVal) {
            this.$socket.emit('support:typing', {
                typing: newVal,
                ticketid: this.ticketid
            })
        }
    },
    async created() {
        setTimeout(() => {
            this.timeout = true
        }, 5000);
        await Promise.all([this.getMessages(), this.markAsRead(), this.chatWSConnect()])
    },
    beforeDestroy() {
        this.$socket.emit('support:unsubscribe', this.ticketid)
    },
    methods: {
        validationMessage: validationMessage(formMessages),
        async getMessages() {
            const data = await getSupportMessages({
                ticketid: this.ticketid,
                hash: this.getHash
            }, this.getterIsAuth)
            this.messages = data

            this.$nextTick(() => {
                this.scrollToEnd()
            })
        },
        async sendMessage() {
            try {
                this.isLoading = true
                const data = await sendMessage({
                    ticketid: this.ticketid,
                    message: this.message,
                    hash: this.getHash
                }, this.getterIsAuth)
                this.messages.push(data)
                this.message = ''
                this.$v.$reset()
                this.$nextTick(() => {
                    this.scrollToEnd()
                })
            } catch (error) {
                eventBus.$emit('showSnackBar', error, 'error');
            } finally {
                this.isLoading = false
            }
        },
        async markAsRead() {
            await readTicket({
                ticketid: this.ticketid,
                hash: this.getHash
            }, this.getterIsAuth)
            this.$emit('readMessages', this.ticketid)
        },
        keyListner(e) {
            if (e.ctrlKey && e.keyCode === 13) {
                this.message += '\n'
                return
            }
            if (!e.ctrlKey && !e.shiftKey && e.keyCode === 13) {
                e.preventDefault();
                if (!this.getterIsAuth) {
                    this.$refs.recaptcha.buttonClick()
                    return
                }
                this.sendMessage()
            }
        },
        scrollToEnd() {
            const elem = document.querySelector('.support-messenger_chat_wrapper')
            elem.scrollTo({ top: 99999999, behavior: 'smooth' })
        },
        userTyping: debounce((self) => {
            self.typing = false
        }, 1000),
        chatWSConnect() {
            this.$socket.emit('support:subscribe', { ticketid: this.ticketid, hash: this.getHash })
            this.$socket.on('support:newMessage', (data) => {
                if (data.author.id === this.getterMainClientInfo.id) return
                this.messages = [...this.messages, data]
                this.$nextTick(() => {
                    this.scrollToEnd()
                })
            })
            this.$socket.on('support:typing', (data) => {
                if (data.role === 'Client' || data.role === 'Lead') return
                this.typingMessage = data.message
            })
        }
    }
}
</script>
