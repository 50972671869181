<template
    lang="html"
    name="Header"
>
    <header
        id="header"
        class="header"
    >
        <div class="header__wrapper">
            <div class="header__logo">
                <a
                    :href="'https://' + getAppDomain"
                    class="header__logo-link header__logo-img"
                />
            </div>
            <div class="header__info">
                <div
                    class="header__btn"
                >
                    <supportphones />
                    <sign-block />
                    <order-cart-button />
                    <control-panel-menu-mobile />
                </div>
            </div>
        </div>
    </header>
</template>
<script>
// components
import ClickOutside from 'vue-click-outside'
import OrderCartButton from '@/components/Header/OrderCartButton.vue'
import Supportphones from '@/components/Header/SupportPhones.vue'
import SignBlock from '@/components/Header/SignBlock'
import ControlPanelMenuMobile from '@/components/Header/ControlPanelMenuMobile'
// directive

export default {
    directives: {
        ClickOutside
    },
    components: {
        Supportphones,
        OrderCartButton,
        SignBlock,
        ControlPanelMenuMobile
    },
    computed: {
        getAppDomain() {
            return process.env.VUE_APP_DOMAIN;
        },
        getAppName() {
            return process.env.APP_NAME;
        },
        getAppUrl() {
            return process.env.APP_URL;
        }
    }
}
</script>
