<template lang="html">
    <div>
        <span
            class="profile-dropdown-mobile__burger"
            @click="openList"
        />
        <Transition name="slide-fade">
            <div
                v-if="openListItem"
                class="profile-dropdown-mobile__list-wrapper"
            >
                <div
                    class="profile-dropdown-mobile__list-topic-wrapper"
                    @click.stop="openListItem = false"
                >
                    <span class="profile-dropdown-mobile__list-topic">
                        Menu
                    </span>
                    <span class="profile-dropdown-mobile__close" />
                </div>
                <div class="profile-dropdown-mobile__container">
                    <div class="profile-dropdown-mobile__client-info-wrapper">
                        <div class="profile-dropdown-mobile__client-info">
                            <avatar
                                :firstname="getterMainClientInfo.firstname"
                                :lastname="getterMainClientInfo.lastname"
                                :email="getterMainClientInfo.email"
                            />
                            <div class="profile-dropdown-mobile__client-info-personality">
                                <span class="profile-dropdown-mobile__client-info-personality-title">My profile</span>
                                <span class="profile-dropdown-mobile__client-info-personality-name">{{ getterMainClientInfo.firstname }} {{ getterMainClientInfo.lastname }}</span>
                            </div>
                        </div>
                    </div>
                    <nav>
                        <ul>
                            <li
                                v-for="item in getMenuList"
                                :key="item.routeName"
                            >
                                <div
                                    class="profile-dropdown-mobile__list-item"
                                    @click="goToPage(item.routeName, item.page)"
                                >
                                    <span
                                        class="profile-dropdown-mobile__list-item-icon"
                                        :class="`profile-dropdown-mobile__list-item-icon-${item.routeName}`"
                                    />
                                    <span class="profile-dropdown-mobile__list-item-text">
                                        {{ item.title }}
                                    </span>
                                </div>
                            </li>
                        </ul>
                        <a
                            href="#"
                            class="profile-dropdown-mobile__list-item"
                            @click.prevent="logout()"
                        >
                            <span class="profile-dropdown-mobile__list-item-icon profile-dropdown-mobile__list-item-icon-logout" />
                            <span class="profile-dropdown-mobile__list-item-text">
                                Sign Out
                            </span>
                        </a>
                    </nav>
                    <div
                        class="profile-dropdown-mobile__support-wrapper"
                        @click="goToPage('support_form')"
                    >
                        <div class="profile-dropdown-mobile__support">
                            <span class="profile-dropdown-mobile__support-icon" />
                            <div class="profile-dropdown-mobile__support-personality">
                                <span class="profile-dropdown-mobile__support-personality-title">Need help?</span>
                                <span class="profile-dropdown-mobile__support-personality-name">Our support</span>
                            </div>
                        </div>
                        <span class="profile-dropdown-mobile__arrow" />
                    </div>
                    <order-cart-button
                        @goToCheckout="openListItem = false"
                    />
                </div>
            </div>
        </Transition>
    </div>
</template>

<script>
import { mapGetters, createNamespacedHelpers } from 'vuex'
import Avatar from '@/components/common/Avatar.vue'
import OrderCartButton from '@/components/Header/OrderCartButton.vue'
import {
    ACTION_LOGOUT_CLIENT
} from '@/store/modules/client/action-types'
import menuConstants from '@/constants/menuConstants.js'

const {
    mapActions: mapClientActions
} = createNamespacedHelpers('client')

export default {
    name: 'ControlPanelMenuMobile',
    components: {
        Avatar,
        OrderCartButton
    },
    data() {
        return {
            openListItem: false
        }
    },
    computed: {
        // TODO: create function to check current window width and send prop to avatar component
        ...mapGetters('client', [
            'getterMainClientInfo'
        ]),
        getMenuList() {
            return menuConstants
        }
    },
    methods: {
        ...mapClientActions([
            ACTION_LOGOUT_CLIENT
        ]),
        openList() {
            this.openListItem = !this.openListItem
        },
        async logout() {
            try {
                await this[ACTION_LOGOUT_CLIENT]()
                this.$router.push({ name: 'login' })
            } catch (e) {
                console.error(e)
            }
        },
        goToPage(route, page) {
            this.$router.push({ name: route, params: { page } })
            this.openListItem = false
        }
    }
}
</script>
<style lang="scss">

.profile-dropdown-mobile{
    &__burger{
        display: none;
        @media screen and (max-width: 576px) {
            display: block;
            background-image: url("~@/assets/img/svg/burger-icon.svg");
            background-repeat: no-repeat;
            background-size: 20px;
            width: 20px;
            height: 17px;
            padding: 0 10px;
            margin-right: 15px;
            cursor: pointer;
        }
    }
    &__list-wrapper{
        display: none;
        @media screen and (max-width: 576px) {
            display: block;
            position: fixed;
            overflow: scroll; /*hidden;*/
            background-color: $white;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            height: 100vh;
            overscroll-behavior: contain;
            z-index: 100;
        }
    }
    &__list-topic-wrapper {
        display:flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid #e7ecf4;
        margin-bottom: 20px;
        padding-right: 35px;
    }
    &__list-topic {
        height: 22px;
        font-weight: 700;
        font-size: 16px;
        line-height: 22px;
        color: #111720;
        padding: 35px 35px 35px 50px;
        display: flex;
        align-items: center;
        position: relative;
        cursor: pointer;
        &::before{
            content: '';
            width: 20px;
            height: 15px;
            background: url('~@/assets/img/svg/arrow.svg') no-repeat;
            position: absolute;
            left: 25px;
        }
    }
    &__container{
        padding: 0 20px;
    }
    &__container .cart-btn-animated{
        display: flex;
        @media screen and (max-width: 576px) {
            margin-left: 0;
            width: 100%;
        }
    }
    &__client-info-wrapper{
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 15px;
        border: 1px solid #EAEAEA;
        border-radius: 4px;
    }
    &__client-info{
        display: flex;
        align-items: center;
    }
    &__client-info-personality{
        display: flex;
        flex-direction: column;
        margin-left: 15px;
        font-size: 14px;
    }
    &__client-info-personality-title{
        font-weight: 400;
        line-height: 20px;
    }
    &__client-info-personality-name{
        font-weight: 700;
        line-height: 20px;
    }
    &__close{
        background-image: url("~@/assets/img/svg/cross.svg");
        background-repeat: no-repeat;
        background-size: 15px;
        width: 20px;
        height: 15px;
        padding: 0 10px;
        cursor: pointer;
    }
    &__list-item{
        display: flex;
        align-items: center;
        padding: 10px;
        cursor: pointer;
        &:hover {
            background-color: #F2F6FF;
        }
    }
    &__list-item-icon{
        width: 25px;
        height: 25px;
        margin-right: 10px;
        position: relative;
        mask-size: auto;
        mask-repeat: no-repeat;
        mask-position: center;
        background: $font-color-main;

        &-account {
            mask-image: url("~@/assets/img/svg/account-icons/user_account.svg");
        }

        &-orders {
            mask-image: url("~@/assets/img/svg/account-icons/clipboard.svg");
        }

        &-billing {
            mask-image: url("~@/assets/img/svg/account-icons/minus-square.svg");
        }

        &-credits {
            mask-image: url("~@/assets/img/svg/account-icons/dollar-sign.svg");
        }

        &-tickets {
            mask-image: url("~@/assets/img/svg/account-icons/phone.svg");
        }

        &-profile {
            mask-image: url("~@/assets/img/svg/account-icons/settings.svg");
        }

        &-friends {
            mask-image: url("~@/assets/img/svg/account-icons/users.svg");
        }

        &-logout {
            mask-image: url("~@/assets/img/svg/account-icons/log-out.svg");
        }
    }
    &__list-item-text{
        font-size: 14px;
        font-weight: 700;
        color: #435A7D;
        //transition: color 0.2s ease;
    }
    &__support-wrapper{
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 15px;
        border: 1px solid rgba($main-color, 0.5);
        border-radius: 4px;
        margin-bottom: 20px;
        cursor: pointer;
    }
    &__support{
        display: flex;
        align-items: center;
    }
    &__support-icon{
        background: rgba($main-color, 0.1);
        border-radius: 50px;
        width: 48px;
        height: 48px;
        &::before {
            content: '';
            display: block;
            min-width: 40px;
            height: 48px;
            mask-image: url('~@/assets/img/svg/phone-forwarded.svg');
            mask-repeat: no-repeat;
            mask-size: 40px;
            mask-position: center;
            background: $main-color;
        }
    }
    &__support-personality{
        display: flex;
        flex-direction: column;
        margin-left: 15px;
        font-size: 14px;
    }
    &__support-personality-title{
        font-weight: 400;
        line-height: 20px;
    }
    &__support-personality-name{
        font-weight: 700;
        line-height: 20px;
    }
    &__arrow {
        background-image: url("~@/assets/img/svg/arrow.svg");
        background-repeat: no-repeat;
        background-size: 15px;
        width: 20px;
        height: 15px;
        transform: rotateY(-180deg);
    }
}

</style>
