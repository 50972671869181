<template lang="html">
    <div class="loadmore">
        <div class="articles-loader">
            <div class="articles-loader__dot" />
            <div class="articles-loader__dot" />
            <div class="articles-loader__dot" />
            <div class="articles-loader__dot" />
            <div class="articles-loader__dot" />
        </div>
    </div>
</template>

<script>
export default {
    name: 'LoadingOverlay'
}
</script>

<style lang="scss" scoped>
.loadmore {
    margin: 25% auto;
}
.articles-loader {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
    width: 100%;
    &__dot {
        background: $main-gradient;
        margin: 5px;
        animation-name: loader;
        animation-duration: 1s;
        animation-iteration-count: infinite;
        animation-direction: alternate;
        &:nth-child(1) {
            animation-delay: 0.2s;
        }
        &:nth-child(2) {
            animation-delay: 0.4s;
        }
        &:nth-child(3) {
            animation-delay: 0.6s;
        }
        &:nth-child(4) {
            animation-delay: 0.8s;
        }
        &:nth-child(5) {
            animation-delay: 1s;
        }
    }
}

@keyframes loader {
    from {
        width: 2px;
        height: 2px;
        border-radius: 1px;
    }
    to {
        width: 20px;
        height: 20px;
        border-radius: 10px;
    }
}
</style>
