import { getCookie, setCookie, deleteCookie } from '@/services/cookie';

export const getReferralHash = () => getCookie('hash_invite')

export const setReferralHash = (hash) => {
    setCookie('hash_invite', hash, { secure: true, 'max-age': 31536000 })
}

export const deleteReferralHash = () => {
    deleteCookie('hash_invite')
}
