<template>
    <div
        class="empty-layout"
        :class="{'empty-layout-login--page': isRouteNameLogin}"
    >
        <header-top-info />
        <entering-form-header
            v-if="isRouteNameLogin"
        />
        <main-header
            v-if="isRouteNameAccountOrTickets"
        />
        <keep-alive v-if="$route.meta.keepAlive">
            <router-view :key="$route.fullpath" />
        </keep-alive>
        <router-view
            v-else
            :key="$route.fullpath"
        />

        <main-footer
            v-if="isRouteNameAccountOrTickets"
        />

        <slot name="modals" />
    </div>
</template>

<script>
import MainHeader from '@/components/Header';
import HeaderTopInfo from '@/components/HeaderTopInfo';
import MainFooter from '@/components/Footer';
import EnteringFormHeader from '@/components/EnteringFormHeader';

export default {
    name: 'EmptyLayout',
    components: {
        EnteringFormHeader,
        HeaderTopInfo,
        MainHeader,
        MainFooter
    },
    computed: {
        isRouteNameAccountOrTickets() {
            return this.$route.meta.showHeader
        },
        isRouteNameLogin() {
            return this.$route.name === 'login' || this.$route.name === 'register' || this.$route.name === 'forgot-password'
        }
    }
}
</script>

<style lang="scss">
    .empty-layout{
        min-height: 100vh;
        display: flex;
        flex-direction: column;
        #page-footer{
            margin-top: auto;
        }
    }
    .empty-layout-login--page{
        height: inherit !important;
        display: flex;
        background: linear-gradient(129deg,rgba($main-color,.1),rgba($second-color,.8)),url("~@/assets/img/bg3.png") 50% 50% no-repeat;
        background-size: cover;
    }
</style>
