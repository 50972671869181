import Api from '@/helpers/api/index.js'

const OAUTH_SERVICES = {
    google: 'google',
    facebook: 'facebook'
}

const OrderWays = {
    checkoutStandard: {
        type: 'standard',
        /* eslint-disable global-require */
        label: 'Save Time',
        title: 'Standard',
        description: 'The project manager will choose the most qualified writer for you.',
        bullets: [
            {
                subtitle: 'Pricing is based on our <b> service calculator. </b>'
            },
            {
                subtitle: 'Writer allocation is managed by our project manager.'
            },
            {
                subtitle: 'You can request your <b>preferred writer</b>, even if they are offline.'
            }
        ],
        icon: 'Check',
        btn: 'Proceed',
        route_name: 'order_new'

    },
    checkoutExpress: {
        type: 'express',
        /* eslint-disable global-require */
        label: 'Save Money',
        title: 'Express',
        description: 'You create a project in one click and pick the writer yourself.',
        bullets: [
            {
                subtitle: 'You are in <b>total control</b> and work with the writer directly.'
            },
            {
                subtitle: 'You get <b>different price offers</b> from different writers.'
            },
            {
                subtitle: 'You pick the <b>best offer</b>, based on the reviews.'
            }
        ],
        icon: 'Check',
        btn: 'Proceed',
        route_name: 'fast-track'

    }
}

const PaymentMethods = {
    paymentWorldpay: {
        type: 'worldpay',
        /* eslint-disable global-require */
        logo: require('@/assets/img/svg/worldpay.svg'),
        title: 'direct'
    },
    paymentWorldpay350: {
        type: 'worldpay350',
        /* eslint-disable global-require */
        logo: require('@/assets/img/svg/worldpay.svg'),
        title: 'alternative'
    },
    paymentBitfinex: {
        type: 'bitfinex',
        /* eslint-disable global-require */
        logo: require('@/assets/img/svg/Bitfinex.svg'),
        title: 'Crypto'
    }
}

export const getSettings = async () => {
    const { data } = await Api.get('/api/settings/get')
    localStorage.setItem('settings', JSON.stringify(data))
}

export const getOauthServices = () => {
    const result = []
    const data = JSON.parse(localStorage.getItem('settings'))
    const google = data.find((item) => item.key === 'socialGoogle')
    const facebook = data.find((item) => item.key === 'socialFacebook')
    if (google?.value === '1') result.push(OAUTH_SERVICES.google)
    if (facebook?.value === '1') result.push(OAUTH_SERVICES.facebook)

    return result
}

export const getCheckoutWays = () => {
    const result = []
    const data = JSON.parse(localStorage.getItem('settings'))
    const checkoutStandard = data.find((item) => item.key === 'checkoutStandard')
    const checkoutExpress = data.find((item) => item.key === 'checkoutExpress')
    if (!checkoutExpress && !checkoutStandard) result.push(OrderWays.checkoutStandard)
    if (checkoutStandard?.value === '1') result.push(OrderWays.checkoutStandard)
    if (checkoutExpress?.value === '1') result.push(OrderWays.checkoutExpress)
    return result
}

export const getAllowedPaymentSystem = () => {
    const result = []
    const data = JSON.parse(localStorage.getItem('settings'))
    const paymentWorldpay = data.find((item) => item.key === 'paymentWorldpay')
    const paymentWorldpay350 = data.find((item) => item.key === 'paymentWorldpay350')
    const paymentBitfinex = data.find((item) => item.key === 'paymentBitfinex')
    if (!paymentWorldpay && !paymentWorldpay350) result.push(PaymentMethods.paymentWorldpay350)
    if (paymentWorldpay350?.value === '1') result.push(PaymentMethods.paymentWorldpay350)
    if (paymentWorldpay?.value === '1') result.push(PaymentMethods.paymentWorldpay)
    if (paymentBitfinex?.value === '1') result.push(PaymentMethods.paymentBitfinex)
    return result
}

export const security = Object.freeze([
    {
        icon: require('@/assets/img/svg/checkout-security/guarantees.svg'),
        title: 'Guarantees',
        description: 'Payment  to the writer is made only after your approval.'
    },
    {
        icon: require('@/assets/img/svg/checkout-security/privacy.svg'),
        title: 'Privacy',
        description: 'Your name and email won’t be know to anyone.'
    },
    {
        icon: require('@/assets/img/svg/checkout-security/sucurity.svg'),
        title: 'Security',
        description: 'Your payment card details are safe with encryption.'
    }
])
