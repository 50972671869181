const Checkout = () => import(/* webpackChunkName: "checkout" */'@/views/checkout/Checkout.vue')
const BatchPage = () => import(/* webpackChunkName: "checkout" */'@/views/checkout/BatchPage')
const ErrorPayment = () => import(/* webpackChunkName: "successful-payment" */'@/views/checkout/ErrorPayment')
const SuccessfulPayment = () => import(/* webpackChunkName: "successful-payment" */'@/views/checkout/SuccessfulPayment')

export default [
    {
    // checkout page
        path: '/checkout',
        component: Checkout,
        name: 'checkout',
        meta: {
            requiresAuth: true,
            layout: 'BreadcrumbsLayout',
            breadcrumbs: {
                bgWrapper: true,
                name: 'Checkout',
                additionalClass: 'bc-horizontal bc-checkout',
                parent_route_names: ['order_new']
            }
        }
    },
    // error page
    {
        path: '/checkout/error',
        component: ErrorPayment,
        name: 'checkout_error',
        meta: {
            requiresAuth: true,
            layout: 'EmptyLayout'
        }
    },
    // success page
    {
        path: '/checkout/success',
        component: SuccessfulPayment,
        name: 'checkout_success',
        meta: {
            requiresAuth: true,
            showHeader: true,
            layout: 'EmptyLayout'
        }
    },
    {
        // invoice page
        path: '/checkout/:batchid',
        component: BatchPage,
        name: 'checkout-batch',
        meta: {
            requiresAuth: true,
            layout: 'BreadcrumbsLayout',
            breadcrumbs: {
                name: 'Payment'
            }
        }
    }
]
