import { cloneDeep } from 'lodash'

import { updateField } from 'vuex-map-fields'

import {
    RESET,
    LOGIN_CLIENT,
    LOGOUT_CLIENT,
    UPDATE_FORM_DATA,
    UPDATE_CLIENT_DATA,
    SET_PHONE_CODE_AND_COUNTRY,
    UPDATE_CLIENT_EMAIL,
    UPDATE_CLIENT_PHONE_DETAILS,
    UPDATE_ADDRESS,
    UPDATE_CHK,
    SET_OAUTH_FORM_DATA,
    OPEN_SOCIAL_MODAL,
    CLOSE_SOCIAL_MODAL,
    CLEAR_OAUTH_FORM_DATA
} from './mutation-types.js'

import { initialState } from './state.js'

export const mutations = {
    updateField,
    [RESET](state) {
        Object.assign(state, cloneDeep(initialState))
    },
    [UPDATE_CLIENT_EMAIL](state, payload) {
        state.client_data.email = payload
    },
    [UPDATE_CLIENT_PHONE_DETAILS](state, payload) {
        state.client_data = {
            ...state.client_data,
            ...payload
        }
    },
    [LOGIN_CLIENT](state) {
        state.auth = true
    },
    [LOGOUT_CLIENT](state) {
        state.auth = false
    },
    [UPDATE_FORM_DATA](state, payload) {
        const object = JSON.parse(JSON.stringify(payload))
        state.form_data = object
    },
    [UPDATE_CLIENT_DATA](state, payload) {
        state.client_data = { ...state.client_data, ...payload }
    },
    [UPDATE_ADDRESS](state, payload) {
        state.client_data = {
            ...state.client_data,
            ...payload
        }
    },
    [SET_PHONE_CODE_AND_COUNTRY](state, payload) {
        state.form_data.phone_code = payload.phone_code
        state.country = payload.country
    },
    [UPDATE_CHK](state, payload) {
        state.client_data = {
            ...state.client_data,
            payload
        }
    },
    [SET_OAUTH_FORM_DATA](state, payload) {
        state.finalizeAuthFormData = {
            ...payload
        }
    },
    [OPEN_SOCIAL_MODAL](state, { url, windowFeatures }) {
        state.socialModal = window.open(url, '', windowFeatures)
    },
    [CLOSE_SOCIAL_MODAL](state) {
        state.socialModal.close()
        state.socialModal = null
    },
    [CLEAR_OAUTH_FORM_DATA](state) {
        state.finalizeAuthFormData = null
    }
};
