<template>
    <div class="support-messenger_ticket__body">
        <p class="support-messenger_ticket__body-last-message">
            <avatar
                :avatar-url="lastMessage.author.avatar_url"
                :avatar-fallback="lastMessage.author.avatar_fallback"
                :name="getName"
                :user="lastMessage.author.type.toLowerCase()"
            />
            <span>
                «{{ lastMessage.details }}»
            </span>
        </p>
        <span class="support-messenger_ticket__bell">
            <span
                v-if="unreadCounter > 0"
                class="bubble--unread"
            >
                {{ unreadCounter }}
            </span>
        </span>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Avatar from '@/components/common/Avatar.vue'

export default {
    components: {
        Avatar
    },
    props: {
        lastMessage: {
            type: Object,
            required: true
        },
        unreadCounter: {
            type: Number,
            required: false,
            default: 0
        }
    },
    computed: {
        ...mapGetters('client', [
            'getterIsAuth'
        ]),
        getName() {
            if (this.lastMessage.author.name === 'You') {
                return null
            }
            return this.lastMessage.author.name
        }
    }
}
</script>
