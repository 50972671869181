<template>
    <fieldset class="form-input__fieldset">
        <legend
            v-if="!isLoginForm"
            class="form-input__legend"
        >
            Phone Number
        </legend>
        <input
            v-model="val"
            class="form-input__input"
            autocomplete="off"
            :class="{ 'error-border': validationError, 'success': isValid, 'form-input__input--placeholder-animation': isLoginForm }"
            inputmode="numeric"
            placeholder=" "
            pattern="[0-9]*"
            name="phone_number"
            @input="onChange($event)"
        >
        <label
            v-if="isLoginForm"
            class="form-input__label"
            style="left: 6px"
        >
            Phone Number
        </label>
        <span
            v-if="value.length > 0"
            @click="clearInput"
        >
            <CloseIcon
                class="form-input__append-icon"
            />
        </span>
        <div class="form-input__error">
            {{ validationError }}
        </div>
    </fieldset>
</template>

<script>
import CloseIcon from 'mdi-vue/Close.vue';
import { eventBus } from '@/helpers/event-bus/'
import { mapPhoneFields } from '@/store/modules/phone'

export default {
    name: 'PhoneNumberInput',
    components: {
        CloseIcon
    },
    props: {
        value: {
            type: String
        },
        type: {
            type: String
        },
        name: {
            type: String
        },
        field: {
            type: String
        },
        errors: {
            type: String
        },
        isValid: {
            type: Boolean
        }
    },
    data() {
        return {
            val: ''
        }
    },
    computed: {
        ...mapPhoneFields([
            'form_data.country',
            'form_data.phone_number'
        ]),
        validationError() {
            return this.errors ? this.errors : '';
        },
        isLoginForm() {
            if (this.$route.name === 'register' || this.$route.name === 'login' || this.$route.name === 'forgot-password') {
                return true
            }
            return false
        }
    },
    mounted() {
        eventBus.$on('clearPhone', () => {
            this.clearInput()
        })
    },
    methods: {
        onChange(event) {
            this.$emit('update:field', event.target.value);
        },
        emitEvent() {
            this.$emit('clear');
        },
        clearInput() {
            this.val = ''
            this.$emit('update:field', '');
        }
    }
};
</script>

<style scoped lang="scss">
    .form-input {
        &__prepend-icon {
            position: absolute;
            top: 50%;
            left: 12px;
            transform: translateY(-50%);
            color: rgba(0, 0, 0, 0.54);
            & + .form-input__input {
                padding-left: 40px;
            }
        }
        &__append-icon {
            position: absolute;
            cursor: pointer;
            top: 50%;
            right: 12px;
            transform: translateY(-50%);
            color: rgba(0, 0, 0, 0.54);
        }

        &__fieldset {
            border: none !important;
            border-radius: $input-border-radius;
            max-height: 50px;
            height: 50px;
            padding: 0;
            position: relative;
            width: 100%;
            .success {
                border: $input-border-success !important;
            }
            .error-border {
                border: $input-border-error !important;
            }
        }

        &__legend {
            display: table;
            color: #b3bbc6;
            caret-color: #b3bbc6;
            padding: 2px 5px 2px 5px;
            font-size: 80%;
            position: absolute;
            left: 6px;
            top: -10px;
            background-color: $white;
            border: none;
        }

        &__input {
            box-sizing: border-box;
            display: inline-block;
            padding: 10px 40px 10px 10px;
            width: 100%;
            user-select: text;
            line-height: 46px;
            outline: none !important;
            border-radius: $input-border-radius;
            font-family: $font-family-base, sans-serif;
            max-height: 48px;
            margin: 0;
        }
        &__error {
            display: inline;
            width: auto;
            text-align: left;
            color: $error-color;
            caret-color: $error-color;
            font-size: 12px;
            min-height: 14px;
            position: relative;
            position: absolute;
            left: 13px;
            bottom: -7px;
            background: $white;
        }
    }
</style>
