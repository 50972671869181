const Terms = () => import(/* webpackChunkName: "terms" */ '@/views/resources/Terms.vue')
const Privacy = () => import(/* webpackChunkName: "terms" */ '@/views/resources/Privacy.vue')

export default [
    {
        path: '/terms',
        component: Terms,
        name: 'terms',
        meta: {
            layout: 'BreadcrumbsLayout',
            breadcrumbs: {
                name: 'Terms Of Service',
                additionalClass: 'bc-horizontal'
            }
        }
    },
    {
        path: '/privacy',
        component: Privacy,
        name: 'privacy',
        meta: {
            layout: 'BreadcrumbsLayout',
            breadcrumbs: {
                name: 'Privacy Policy',
                additionalClass: 'bc-horizontal'
            }
        }
    }
]
