<template lang="html">
    <div
        v-click-outside="outsideCloseMenu"
        class="signin"
        :class="{ 'signin__active' : menuState }"
        @click="triggerMenu({ name: 'client-menu' })"
    >
        <avatar
            class="signin__user-avatar"
            :firstname="getterMainClientInfo.firstname"
            :lastname="getterMainClientInfo.lastname"
            :email="getterMainClientInfo.email"
        />
        <div :class="{'signin__tab-open-on-hover' : menuState}">
            <div class="profile-dropdown">
                <nav class="profile-dropdown__nav">
                    <div class="profile-dropdown__list">
                        <router-link
                            :to="{ name: 'account' }"
                            class="profile-dropdown__list-item"
                        >
                            <span class="profile-dropdown__icon profile-dropdown__icon-account" />
                            <span class="profile-dropdown__list-item__text profile-dropdown__list-item__text_accaunt">Dashboard</span>
                        </router-link>

                        <router-link
                            :to="{ name: 'orders', params: { page: '1' } }"
                            class="profile-dropdown__list-item"
                        >
                            <span class="profile-dropdown__icon profile-dropdown__icon-orders" />
                            <span class="profile-dropdown__list-item__text">Projects</span>
                        </router-link>

                        <router-link
                            :to="{ name: 'tickets', params: { page: '1' } }"
                            class="profile-dropdown__list-item"
                        >
                            <span class="profile-dropdown__icon profile-dropdown__icon-tickets" />
                            <span class="profile-dropdown__list-item__text">Help Center</span>
                        </router-link>

                        <router-link
                            :to="{ name: 'profile', params: {} }"
                            class="profile-dropdown__list-item"
                        >
                            <span class="profile-dropdown__icon profile-dropdown__icon-profile" />
                            <span class="profile-dropdown__list-item__text">Settings</span>
                        </router-link>

                        <router-link
                            :to="{ name: 'billing', params: { page: '1' } }"
                            class="profile-dropdown__list-item"
                        >
                            <span class="profile-dropdown__icon profile-dropdown__icon-billing" />
                            <span class="profile-dropdown__list-item__text profile-dropdown__list-item__text_billing">Billing</span>
                        </router-link>

                        <router-link
                            :to="{ name: 'credits', params: { page: '1' } }"
                            class="profile-dropdown__list-item"
                        >
                            <span class="profile-dropdown__icon profile-dropdown__icon-credits" />
                            <span
                                class="profile-dropdown__list-item__text profile-dropdown__list-item__text_credit"
                            >
                                Credits
                            </span>
                        </router-link>

                        <router-link
                            :to="{ name: 'friends', params: { page: '1' } }"
                            class="profile-dropdown__list-item"
                        >
                            <span class="profile-dropdown__icon profile-dropdown__icon-friends" />
                            <span class="profile-dropdown__list-item__text profile-dropdown__list-item__text_friends">
                                Referrals
                            </span>
                        </router-link>

                        <a
                            href="#"
                            class="profile-dropdown__list-item logout-link"
                            @click.prevent="logout()"
                        >
                            <span class="profile-dropdown__icon profile-dropdown__icon-logout" />
                            <span class="profile-dropdown__list-item__text profile-dropdown__list-item__text_out">
                                Sign Out
                            </span>
                        </a>
                    </div>
                </nav>
            </div>
        </div>
        <div
            v-if="menuState"
            class="blurred-background"
        />
    </div>
</template>

<script>
import ClickOutside from 'vue-click-outside'
import { mapGetters, createNamespacedHelpers } from 'vuex'
import Avatar from '@/components/common/Avatar.vue'
import filtersMixin from '@/mixins/filtersMixin.js'
import overlayMixin from '@/mixins/overlayMixin'
import {
    ACTION_LOGOUT_CLIENT
} from '@/store/modules/client/action-types'

const {
    mapActions: mapClientActions
} = createNamespacedHelpers('client')

export default {
    components: {
        Avatar
    },
    directives: {
        ClickOutside
    },
    mixins: [
        filtersMixin,
        overlayMixin
    ],
    computed: {
        // TODO: create function to check current window width and send prop to avatar component
        ...mapGetters('client', [
            'getterMainClientInfo'
        ])
    },
    methods: {
        ...mapClientActions([
            ACTION_LOGOUT_CLIENT
        ]),
        outsideCloseMenu() {
            this.closeMenu({ overlayAvailability: true, lockAvailability: true })
        },
        async logout() {
            try {
                await this[ACTION_LOGOUT_CLIENT]()
                this.$router.push({ name: 'login' })
            } catch (e) {
                console.error(e)
            }
        }

    }
}
</script>
<style lang="scss" scoped>
.signin {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0 30px;
    height: 100%;
    cursor: pointer;
    position: relative;
    transition: 0.2s;

    &__user-avatar {
        display: flex;
        align-items: center;
        &::after {
            content: "";
            display: block;
            min-width: 20px;
            width: 20px;
            height: 10px;
            mask-image: url("~@/assets/img/svg/next.svg");
            mask-size: contain;
            mask-position: center;
            mask-repeat: no-repeat;
            background: $font-color-main;
            transform: rotate(90deg);
            margin-left: 5px;
        }
    }

    &__active {
        user-select: none;
        z-index: 999;
        transition: 0.2s;

        &:after {
            content: "";
            position: absolute;
            width: 100%;
            height: 1px;
            bottom: -1px;
            left: 0;
            background: #fff;
        }

        .signin__user-avatar::after {
            transform: rotate(270deg);
            background: $second-color;
        }
    }

    &__tab-open-on-hover {
        z-index: 999;
        transition: 0.2s;

        .profile-dropdown {
            display: block;
            transition: 0.2s;

            &__nav {
                overflow-y: auto;
                overflow-x: hidden;
                height: auto;
            }
        }

        .signin__user-name:after {
            transform: translateY(-3px) rotateX(180deg);
        }
    }

    &__user-mobile {
        display: none;
    }

    &__user-info {
        display: flex;
        flex-direction: column;
        font-size: 12px;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.17;
        letter-spacing: normal;
        @media screen and (max-width: 1600px) {
            display: none;
        }
    }

    &__user-email {
        font-weight: 500;
        color: #7c8a9a;
        margin-bottom: 6px;
    }

    &__user-name {
        font-weight: bold;
        text-transform: uppercase;
        color: $font-color-main;

        &:after {
            display: inline-block;
            content: "";
            width: 0px;
            height: 0px;
            margin: 0 0 0 5px;
            border-top: 5px solid $font-color-main;
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            border-bottom: 5px solid transparent;
            transform: translateY(3px);
            transition: 0.2s;
        }

        &__opened {
            &:after {
                transform: translateY(-3px) rotateX(180deg);
                transition: 0.2s;
            }
        }
    }
}

@media (max-width: 576px) {
    .signin {
        display: none;
    }
}

</style>
