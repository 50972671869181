<template>
    <div
        v-if="dialog"
        class="confirm-modal"
        @click.self="close()"
    >
        <div class="confirm-modal__dialog">
            <header class="confirm-modal__header">
                <h2>{{ title }}</h2>
                <span
                    class="confirm-modal__close"
                    @click="close()"
                >
                    <CloseIcon />
                </span>
            </header>
            <section class="confirm-modal__content">
                <span>{{ message }}</span>
            </section>
            <footer>
                <div class="confirm-modal__two-button-block">
                    <button
                        class="confirm-modal__two-button confirm-modal__two-button--gray"
                        @click.prevent="close()"
                    >
                        cancel
                    </button>
                    <button
                        class="confirm-modal__two-button confirm-modal__two-button--main"
                        @click.prevent="submit()"
                    >
                        ok
                    </button>
                </div>
            </footer>
        </div>
    </div>
</template>

<script>
import CloseIcon from 'mdi-vue/Close.vue';

export default {
    name: 'ConfirmModal',
    components: {
        CloseIcon
    },
    data: () => ({
        dialog: false,
        resolve: null,
        reject: null,
        message: null,
        title: null
    }),
    methods: {
        open(title, message) {
            this.dialog = true
            this.title = title
            this.message = message
            return new Promise((resolve, reject) => {
                this.resolve = resolve
                this.reject = reject
            })
        },
        submit() {
            this.resolve(true)
            this.dialog = false
        },
        close() {
            this.resolve(false)
            this.dialog = false
        }
    }
}
</script>

<style lang="scss">
.confirm-modal {
    position: fixed;
    overflow: hidden;
    background-color: rgba(11, 19, 31, .45);
    backdrop-filter: blur(3px);
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: 100vh;
    overscroll-behavior: contain;
    z-index: 900;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;

    &__dialog {
        max-width: 500px;
        width: 100%;
        background-color: #fff;
        -webkit-box-shadow: 0 10px 40px rgba(25, 50, 71, .2);
        box-shadow: 0 10px 40px rgba(25, 50, 71, .2);
        border-radius: $input-border-radius;
        //position: fixed;
        top: 90px;
        left: calc(50% - 250px);
        z-index: 999999999;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    &__header {
        font-weight: 500;
        color: $font-color-main;
        font-size: 24px;
        border-bottom: 1px solid $border-light;
        padding: 30px 30px 20px;
        position: relative;
    }
    &__close {
        position: absolute;
        right: 20px;
        top: 50%;
        transform: translateY(-50%);
        color: $second-color;
        cursor: pointer;
    }
    &__content {
        padding: 30px 30px 0;
        color: #757c89;
        font-size: 16px;
        line-height: 1.3;
    }
    &__two-button-block {
        display: flex;
        justify-content: space-between;
        padding: 20px;
    }
    &__one-button-block {
        display: flex;
        justify-content: center;
        padding: 20px;
    }
    &__two-button {
        padding: 20px 0;
        width: calc(50% - 10px);
        text-transform: uppercase;
        font-size: 16px;
        font-weight: bold;
        border: none;
        border-radius: $btn-base__border-radius;
        cursor: pointer;
        &--gray {
            background-color: #f5f5f5;
            &:hover {
                background-color: rgba(145, 149, 154, 0.2);
                transition: 0.5s;
            }
        }
        &--main {
            background: $btn-main-background;
            box-shadow: none;
            color: $btn-main-color;
            &:hover {
                background: $btn-main-background-hover;
                transition: 0.5s;
                color: $btn-main-color-hover;
            }
        }
    }
    &__one-button {
        padding: 20px 0;
        width: 80%;
        text-transform: uppercase;
        font-size: 16px;
        font-weight: bold;
        border: none;
        border-radius: $btn-base__border-radius;
        cursor: pointer;
        &--main {
            background: $btn-main-background;
            box-shadow: none;
            color: $btn-main-color;
            &:hover {
                background: $btn-main-background-hover;
                color: $btn-main-color-hover;
                transition: 0.5s;
            }
        }

    }
    .modal__header-description {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 18px;
        margin-top: 10px;
    }
}

</style>
